import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import React from 'react'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen025.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
      />

      {/* ----------- Order Start   */}
      <AsideMenuItemWithSub
        to='/pages/Order'
        title='Commande'
        icon='/media/icons/duotune/general/gen025.svg'
      >
        <AsideMenuItem to='/admin/ListOrder' title='list des commande' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* ----------- Order End  */}

      {/* ----------- Order Offline Start   */}
      <AsideMenuItemWithSub
        to='/pages/OrderOffline'
        title='Commande offline'
        icon='/media/icons/duotune/general/gen025.svg'
      >
        <AsideMenuItem to='/admin/List-OrderOffline' title='list des commande offline' hasBullet={true} />
      </AsideMenuItemWithSub> 
      {/* ----------- Order Offline End */}

       {/* ----------- Reservation Start   */}
       <AsideMenuItemWithSub
        to='/pages/Reservation'
        title='Reservation'
        icon='/media/icons/duotune/general/gen025.svg'
      >
        <AsideMenuItem to='/admin/ListReservation' title='list des Reservation' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* ----------- Reservation End  */}

       {/* ----------- ReservationOffline Start   */}
       <AsideMenuItemWithSub
        to='/pages/ReservationOffline'
        title='Reservation Offline'
        icon='/media/icons/duotune/general/gen025.svg'
      >
        <AsideMenuItem to='/admin/List-ReservationOffline' title='list des Reservation Offline' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* ----------- Reservation End  */}

      {/* ----------- Category Start   */}
      <AsideMenuItemWithSub
        to='/pages/Category'
        title='Categories'
        icon='/media/icons/duotune/general/gen025.svg'
      >
        <AsideMenuItem to='/admin/AddCategory' title='Ajouter categorie' hasBullet={true} />
        <AsideMenuItem to='/admin/ListCategory' title='List des categories' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* ----------- Category End  */}

      {/* ----------- Sous category Start   */}
      <AsideMenuItemWithSub
        to='/pages/SubCategory'
        title='Sous Categories'
        icon='/media/icons/duotune/general/gen025.svg'
      >
        <AsideMenuItem to='/admin/AddSubCategory' title='Ajouter Sous Categorie' hasBullet={true} />
        <AsideMenuItem to='/admin/ListSubCategory' title='list des sous categories' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* ----------- Sous category End  */}

      {/* ----------- Product Start   */}
      <AsideMenuItemWithSub
        to='/pages/Product'
        title='Produits'
        icon='/media/icons/duotune/general/gen025.svg'
      >
        <AsideMenuItem to='/admin/AddProduct' title='Ajouter produit' hasBullet={true} />
        <AsideMenuItem to='/admin/ListProduct' title='list des produits' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* ----------- Product End  */}


      {/* ----------- Variante Start   */}
       <AsideMenuItemWithSub
        to='/pages/Variante'
        title='Variante'
        icon='/media/icons/duotune/general/gen025.svg'
      >
        <AsideMenuItem to='/admin/AddVariante' title='Ajouter variante' hasBullet={true} />
        <AsideMenuItem to='/admin/ListVariante' title='list des Variante' hasBullet={true} />
      </AsideMenuItemWithSub>

      {/* ----------- Pack Start   */}
       <AsideMenuItemWithSub
        to='/pages/Pack'
        title='Pack'
        icon='/media/icons/duotune/general/gen025.svg'
      >
        <AsideMenuItem to='/admin/Add-Pack' title='Ajouter Pack' hasBullet={true} />
        {/* <AsideMenuItem to='/admin/ListVariante' title='list des Variante' hasBullet={true} /> */}
      </AsideMenuItemWithSub>

     {/* ----------- Zone Start   */}
       <AsideMenuItemWithSub
        to='/pages/Zone'
        title='Zone'
        icon='/media/icons/duotune/general/gen025.svg'
      >
        <AsideMenuItem to='/admin/Add-Zone' title='Ajouter Zone' hasBullet={true} />
        <AsideMenuItem to='/admin/List-Zone' title='list des Zone' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* ----------- Zone End  */}


     {/* ----------- Zone Start   */}
       <AsideMenuItemWithSub
        to='/pages/Table'
        title='Table'
        icon='/media/icons/duotune/general/gen025.svg'
      >
         <AsideMenuItem to='/admin/AddTable' title='Ajouter Table' hasBullet={true} />
        <AsideMenuItem to='/admin/ListTable' title='list des Table' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* ----------- Zone End  */}


     

      {/* ----------- slider Start   */}
      <AsideMenuItemWithSub
        to='/pages/Slider'
        title='Slider'
        icon='/media/icons/duotune/general/gen025.svg'
      >
        <AsideMenuItem to='/admin/Add-Slider' title='Ajouter slider' hasBullet={true} />
        <AsideMenuItem to='/admin/List-Slider' title='List des slider' hasBullet={true} />
      </AsideMenuItemWithSub>

      {/* ----------- utilisateur Start   */}
       <AsideMenuItemWithSub
        to='/pages/User'
        title='Utilisateur'
        icon='/media/icons/duotune/general/gen025.svg'
      >
        <AsideMenuItem to='/admin/List-Admin' title='List Admin' hasBullet={true} />
        <AsideMenuItem to='/admin/List-Client' title='List Client' hasBullet={true} />
        <AsideMenuItem to='/admin/Add-User' title='Ajouter un utilisateur' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* ----------- Order End  */}


      {/* <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        icon='/media/icons/duotune/ecommerce/ecm007.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
      /> */}
      {/* <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}
