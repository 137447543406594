import React, { useState, useEffect, ChangeEvent } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { apiUrl, varianteImgUrl } from '../../../_metronic/helpers/Helpers'
import { VarianteProps } from '../../../_metronic/helpers/Props'
import { compressAndAppendImage} from '@mbs-dev/react-image-compressor'
import slugify from 'slugify';
import { useProductContext } from '../../context/ProductContext'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




const UpdateVariante: React.FC = () => {

  const [isloading, setIsLoading] = useState(false)
  const [isSubloading, setIsSubLoading] = useState(false)
  const { id } = useParams()
  const [newImage, setNewImage] = useState<File | null>(null)
  const [variante, setVarinate] = useState<VarianteProps>()
  const [SubExist, setSubExist] = useState(false)
  const [parentTitle, setParentTitle] = useState()
  const [imagefile, setImage] = useState<File | any>(null)
  const [file, setFile] = useState<string>("")
  const { product } = useProductContext()



  const Schema = Yup.object().shape({
    title: Yup.string(),
    picture: Yup.string(),
    price: Yup.number(),
    pointConsommable: Yup.string(),
    pointBeneficier: Yup.string(),
    product: Yup.string().oneOf(
      product.map((product) => product.id.toString()),
      'Sélectionnez un produit valide'
    )
})



  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/variantes/${id}`);

      console.log(response.data);


      setVarinate(response.data)

      await setValues({
        title: response.data.title,
        picture: response.data.picture,
        price: response.data.price,
        pointConsommable: response.data.pointConsommable,
        pointBeneficier:response.data.pointBeneficier,
        product:response.data.product
      })
    } catch {
    }
  }
  useEffect(() => {
    fetchData()
  }, [])


  const formik = useFormik({
    initialValues: {
      title: "",
      picture: "",
      price: "",
      pointConsommable:"",
      pointBeneficier:"",
      product:""

    },
    validationSchema: Schema,
    onSubmit: async (values, { resetForm }) => {

      try {
        setIsLoading(true)
        const formData = new FormData()
        formData.append('title', values.title)
        const slug = generateSlug(values.title)
        formData.append('slug', slug)
        formData.append('price', values.price)
        formData.append('pointConsommable', values.pointConsommable)
        formData.append('pointBeneficier', values.pointBeneficier)
        if(newImage){
          await compressAndAppendImage(newImage as unknown as File, formData,'picture',0.4)
        }


        const response = await axios.post(`${apiUrl}/variante-update/${id}`, formData);
        
        if (response.status === 200) {
          setIsLoading(false)
          toast.success('Variante est modifier avec succès'); 
        }else {
          toast.error('Erreur lors de la modification de variante');
        }

      } catch {
        toast.error('Erreur lors de la communication avec le serveur');

      }

      resetForm()
    },
  })

  

  function generateSlug(inputString: string): string {
    let string = inputString.replace(/&/g, 'et').replace(/'|’/g, '-').replace('$', '');

    const transliterated = slugify(string, {
      lower: true,
      remove: /[^a-zA-Z0-9 -]/g,
    });

    const slug = transliterated.replace(/ /g, '-').replace(/-+/g, '-').replace(/^-+|-+$/g, '');

    return slug;
  }


  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues
  } = formik

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setNewImage(e.target.files[0])
    }
  }



  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#product'
          aria-expanded='true'
          aria-controls='product'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Modifier Une Variante</h3>
          </div>
        </div>

        <div id='product' className='collapse show'>
          <form onSubmit={handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>

              <div className='row mb-6'>
                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-lg-6 col-md-6 fv-row'>
                      <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Nom De Variante</label>

                      <input
                        type='text'
                        name='title'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Nom de categorie'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                      />
                      {formik.touched.title && formik.errors.title && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.title}</div>
                        </div>
                      )}
                    </div>

                    <div className='col-lg-6 col-md-6 fv-row'>
                      <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Ancienne image</label>

                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-70px'>
                          <img
                            src={`${varianteImgUrl}/${values.picture}`}
                            className='w-100 shadow'
                            alt=''
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <div className='col-lg-6 col-md-6 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Nouveau Image</label>
                  <input
                    type="file"
                    name="picture"
                    accept=".png, .jpg, .jpeg, .webp"
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    onChange={handleImageChange} />

                  {newImage ? (
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-70px'>
                        <img src={URL.createObjectURL(newImage)} alt='' className='w-100 shadow mt-2' />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                </div>
                <div className='row mb-6'>
                <div className='col-lg-3 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>prix</label>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='prix'
                      {...formik.getFieldProps('price')}
                    />
                    {formik.touched.price && formik.errors.price && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.price}</div>
                      </div>
                    )}
                  </div>
                  <div className='col-lg-4 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Point consommable</label>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Point_consommable'
                      {...formik.getFieldProps('pointConsommable')}
                    />
                    {formik.touched.pointConsommable && formik.errors.pointConsommable && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.pointConsommable}</div>
                      </div>
                    )}
                  </div>
                  <div className='col-lg-4 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Point beneficier</label>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Point beneficier'
                      {...formik.getFieldProps('pointBeneficier')}
                    />
                    {formik.touched.pointBeneficier && formik.errors.pointBeneficier && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.pointBeneficier}</div>
                      </div>
                    )}
                  </div>
                  </div>
              </div>
              

              <div className='row mb-6'>
                    <div className='col-lg-6 col-md-6 fv-row'>
                      <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Produit</label>

                      <select
                        // name="categorie"
                        className="form-select"
                        value={formik.values.product}
                        onChange={(event) => {
                          formik.setFieldValue('product', event.target.value)
                        }}
                      >
                        <option value="produit">Produit</option> 
                        {product.map((products: any, index) => {
                          return (
                            <option key={index} value={products.id}>
                              {products.title}
                            </option>
                          )
                        })}
                      </select>
                      {formik.touched.product && formik.errors.product && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.product}</div>
                        </div>
                      )}
                    </div>
                    </div>

            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={isloading}>
              {!isloading && 'Save Changes'}
              {isloading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          </form>
        </div>

      </div>






    </>
  )
}

export default UpdateVariante