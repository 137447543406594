import React, { useState } from 'react'
import { Dropdown, Modal } from 'react-bootstrap'
import { apiUrl, productImgUrl, uploadUrl, varianteImgUrl} from '../../../_metronic/helpers/Helpers'
import { OfflineOrderModalProps } from '../../../_metronic/helpers/Props'
import { formatDate, formatPrice } from '@mbs-dev/react-helpers'
import axios from 'axios'


const OfflineOrderDetailsModal: React.FC<OfflineOrderModalProps> = ({ order, show, onHide }) => {

  const [paymentStatus, setPaymentStatus] = useState(order?.paymentStatus || ''); // Initialize with existing status

  const handlePaymentStatusChange = async (status: string | null) => {
    if (status && order?.id) {
      try {
        // Create FormData object to send the status
        const formData = new FormData();
        formData.append('paymentStatus', status);

        console.log("id order:", order.id);
        // Update payment status in the backend
        const response = await axios.post(`${apiUrl}/update-offlinepaymentstatus/${order.id}`, formData);
        
        if (response.status === 200) {
          // If successful, update the state to reflect the new status immediately
          setPaymentStatus(status); // Update the local state
          console.log('Payment status updated successfully:', status);
        } else {
          console.error('Failed to update payment status:', response.data);
        }
      } catch (error) {
        console.error('Error updating payment status:', error);
      }
    }
  };

  const renderStatusBadge = (status: string) => {
    let badgeClass = '';
    let badgeText = '';

    switch (status) {
      case 'pending':
        badgeClass = 'badge badge-light-primary fs-6 fw-bold px-4';
        badgeText = 'En cours';
        break;
      case 'payer':
        badgeClass = 'badge badge-light-info fs-6 fw-bold px-4';
        badgeText = 'Payer';
        break;
      case 'annuler':
        badgeClass = 'badge badge-light-warning fs-6 fw-bold px-4';
        badgeText = 'Annulé';
        break;
      default:
        return null; // Do not render anything if status doesn't match
    }

    return <span className={badgeClass}>{badgeText}</span>;
  };
  return (
    <Modal show={show} onHide={onHide} centered dialogClassName='order-adresse-modal'>
      <Modal.Header closeButton>
        <Modal.Title className='fs-3'>Liste Des Articles</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-125px rounded-start'>Image</th>
                <th className='ps-4 min-w-125px rounded-start'>Nom Produit</th>
                <th className='min-w-100px'>Quantité</th>
                <th className='ps-4 min-w-100px'>Prix Unitaire</th>
              </tr>
            </thead>
            <tbody>
              {
                order?.orderOfflineItem.map((o: any, index: number) => (
                  <tr key={index}>                    
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-50px'>
                          <img
                            src={`${productImgUrl}/${o.product?.picture}`}
                            className='w-100'
                            alt=''
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className='text-dark fw-bold d-block mb-1 fs-6'>
                        {`${o.product ? o.product?.title : o.pack?.title}`}
                      </span>
                      {o.isvariant ?
                        <div className="d-flex alight-items-center">
                          <div className={`subvariant-image-content mx-1 d-flex-center c-pointer selected-border`} >
                            <div className="subvariant-image d-flex-center">
                            <img
                            src={`${varianteImgUrl}/${o.variante.picture}`}
                            className='w-100'
                            alt=''
                          />                             
                          </div>
                          </div>
                          <div className="subvariant-title pt-1 text-info fw-bold">
                            {`${o.variant?.reference} ${o.variant?.label}`}
                          </div>
                        </div>
                        :
                        null
                      }
                    </td>

                    <td>
                      <span className='text-dark fw-bold d-block mb-1 fs-6'>
                        <span className="text-info">{o.quantity}</span> {`${o.product ? 'Produit' : 'Pack'}`}
                      </span>
                    </td>

                    <td>
                      <span className='text-dark fw-bold d-block mb-1 ps-3 fs-6'>
                        <span className="text-info">{formatPrice(o.product?.price)}</span> DH
                      </span>
                    </td>

                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>

        <hr className='hr-tag' />

        <div className="content">
  <h3 className='text-info mb-5'>Autre Informations :</h3>


  <div className='d-flex flex-column'>
    <p>
      <span className='fs-4 min-w-150px d-inline-block'>
      N° de commande :  {order?.reference}
      </span>
    </p>

    <p>
      <span className='fs-4 min-w-150px d-inline-block'>
        Methode de payemet :
        {order?.paymentMethode === 'espece' && (
          <span className='badge badge-light-info fs-6 fw-bold px-4'>Espece</span>
        )}

      </span>
    </p>

    <p className='d-flex align-items-center'>
      <span className='fs-4 min-w-150px d-inline-block'>Statut de paiement :</span>
      <Dropdown onSelect={handlePaymentStatusChange} className="ms-2">
        <Dropdown.Toggle variant="link" className="p-0">
          {renderStatusBadge(order?.paymentStatus)} {/* Update to use paymentStatus state */}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item eventKey="pending">{renderStatusBadge('pending')}</Dropdown.Item>
          <Dropdown.Item eventKey="payer">{renderStatusBadge('payer')}</Dropdown.Item>
          <Dropdown.Item eventKey="annuler">{renderStatusBadge('annuler')}</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </p>
  </div>
</div>
      </Modal.Body>
    </Modal>
  )
}

export default OfflineOrderDetailsModal