
import React, { useState, ChangeEvent, useMemo } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import axios from 'axios'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { useSubCategoryContext } from '../../context/SubCategoryContext'
import { useProductContext } from '../../context/ProductContext'
import { encodeHtmlTags, slugGenerator, getFirstWord, removeHtmlTags, notify } from '@mbs-dev/react-helpers'
import { FormLabel, apiUrl } from '../../../_metronic/helpers/Helpers'
import {compressAndAppendImage} from '@mbs-dev/react-image-compressor';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import slugify from 'slugify';
import { useSubCategoriesParentContext } from '../../context/SubCategoriesParentContext'
import ReactEditor, { config } from '@mbs-dev/react-editor'
import { PackItemProps } from '../../../_metronic/helpers/Props'



const AddPack: React.FC = () => {

  const [loading, setIsLoading] = useState(false)
  const [imageFile, setimageFile] = useState<File>()
  const [description, setDescription] = useState('')
  const [ingredients, setIngredients] = useState('')   
  const editorConfig = useMemo(() => config(false), [])
  const [disponibiliteState, setDisponibiliteState] = useState(1) 
  const [packItem, setPackItem] = useState<PackItemProps[]>([])
  const [loadingProduct, setLoadingProduct] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState<any[]>([])
  const animatedComponents = makeAnimated()





  const Schema = Yup.object().shape({
    title: Yup.string().required('Le nom de categorie est obligatoire'),
    picture: Yup.string().required("L'picture de categorie est obligatoire"),
    price: Yup.number(),
    point: Yup.number(),
    dateDebut:Yup.string(),
    dateFin:Yup.string(),
    status:Yup.string(),
    description: Yup.string(),

  })

  const formik = useFormik({
    initialValues: {
      title: "",
      picture: "",
      price: "",
      point: "",
      description: "",
      dateDebut:"",
      dateFin:"",
      status:"",
    },


    validationSchema: Schema,
    onSubmit: async (values) => {
      // setIsLoading(true)

      const formData = new FormData()
      const meta = removeHtmlTags(description)
      const status = disponibiliteState === 1 ? 'enable' : 'disable'
      const metaDescription = meta.substring(0, 256)
      formData.append('title', values.title)
      formData.append('price', values.price)
      formData.append('point', values.point)
      formData.append('description', encodeHtmlTags(description))
      formData.append('status', status)
      formData.append('dateDebut', values.dateDebut)
      formData.append('dateFin', values.dateFin)
      formData.append('status', values.status)
      formData.append('slug', generateSlug(values.title))
      await compressAndAppendImage(values.picture as unknown as File, formData,'picture',0.4)
      

      const response = await axios.post(`${apiUrl}/packs`, formData)
      try{
      if (response.status === 200) {
        setIsLoading(false)
        toast.success('Pack est enregistré avec succès');

      }
      else {
        toast.error('Erreur lors de l\'enregistrement de pack');
      }
    } catch (error) {
      toast.error('Erreur lors de la communication avec le serveur');
    }

      // const checkSlug = generateSlug(values.title)



    },
  })

  function generateSlug(inputString: string): string {
    let string = inputString.replace(/&/g, 'et').replace(/'|’/g, '-').replace('$', '');

    const transliterated = slugify(string, {
        lower: true,
        remove: /[^a-zA-Z0-9 -]/g,
    });

    const slug = transliterated.replace(/ /g, '-').replace(/-+/g, '-').replace(/^-+|-+$/g, '');

    return slug;
}

      const handleDescriptionChange = (content: string) => {
        const formattedContent = content.replace('. ', '.<br />').replace(' : ', ': <br />')
        setDescription(formattedContent)
      }



      const handleIngredientsChange = (content: string) => {
        const formattedContent = content.replace('. ', '.<br />').replace(' : ', ': <br />')
        setIngredients(formattedContent)
      }

      // const options = products.map((product: any) => ({
      //   value: product.id,
      //   label: `${product.title} | ${product.sellingPrice} DH`,
      // }))

      const handleChangeSelect = (selectedOptions: any) => {
        const selectedValues = selectedOptions.map((option: any) => (option.value))
        setSelectedProducts(selectedValues)
      }


  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#product'
        aria-expanded='true'
        aria-controls='product'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Ajouter Un Pack</h3>
        </div>
      </div>

      <div id='product' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>

          <div className='card-body border-top p-9'>

            <div className='row mb-6'>
              <div className='col-lg-12'>
                <div className='row'>
                  <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Nom de produit</label>

                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Nom de categorie'
                      {...formik.getFieldProps('title')}
                    />
                    {formik.touched.title && formik.errors.title && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.title}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Image</label>
                    <input
                      type="file"
                      name="picture"
                      accept=".png, .jpg, .jpeg, .webp"
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {

                        if (e.target.files && e.target.files[0]) {
                          // setFile(URL.createObjectURL(e.target.files[0]))
                          // setpicture(e.target.files[0])
                          formik.setFieldValue('picture', e.target.files[0])
                        }
                      }} />
                    {formik.touched.picture && formik.errors.picture && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.picture}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row'>
                  <div className='col-lg-4 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>prix</label>

                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='prix'
                      {...formik.getFieldProps('price')}
                    />
                    {formik.touched.price && formik.errors.price && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.price}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-4 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>point</label>

                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='point'
                      {...formik.getFieldProps('pointConsommable')}
                    />
                    {formik.touched.point && formik.errors.point && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.point}</div>
                      </div>
                    )}
                  </div>

                   <div className='col-lg-3 col-md-4 fv-row'>
                  <label className='col-lg-10 col-md-10 col-form-label required fw-bold fs-6'>
                    Statut de pack
                  </label>
                  <div className='form-check form-check-custom form-check-solid form-check-info'>
                    <input
                      type='radio'
                      name='disponibiliteState'
                      id='enable'
                      className='form-check-input form-check-disponibilite'
                      checked={disponibiliteState === 1}
                      onChange={() => setDisponibiliteState(1)}
                      onBlur={formik.handleBlur}
                      value={1}
                    />
                    <label htmlFor='enable' className='fs-5 ms-2 me-4 col-form-label fw-bold'>
                      Active
                    </label>

                    <input
                      type='radio'
                      name='disponibiliteState'
                      id='disable'
                      className='form-check-input form-check-deactive form-check-disponibilite'
                      checked={disponibiliteState === 0}
                      onChange={() => setDisponibiliteState(0)}
                      onBlur={formik.handleBlur}
                      value={0}
                    />
                    <label htmlFor='disable' className='fs-5 ms-2 col-form-label fw-bold'>
                      Desactive
                    </label>

                  </div>
                </div>       
                 </div>


                 <div className="row mb-6">
                 <div className='col-lg-3 col-md-3 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Date de debut de pack</label>
                    <input
                      type='date'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='dateDebut'
                      {...formik.getFieldProps('dateDebut')}
                    />
                    {formik.touched.dateDebut && formik.errors.dateDebut && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.dateDebut}</div>
                      </div>
                    )}
                  </div>

                 <div className='col-lg-3 col-md-3 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Date de fin de pack</label>
                    <input
                      type='date'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='dateFin'
                      {...formik.getFieldProps('dateFin')}
                    />
                    {formik.touched.dateFin && formik.errors.dateFin && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.dateFin}</div>
                      </div>
                    )}
                  </div>
                 </div>

                 
                 <div className="row mb-6">
              <div className="col-lg-12 col-md-12 fv-row">
                <FormLabel value='Description' isRequired={false} />
                <ReactEditor
                  config={editorConfig}
                  value={description}
                  onChange={(desc: any) => setDescription(desc)}
                />
              </div>
            </div>
              </div>
            </div>


            <div className='row mb-6'>
              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-8 col-md-8 col-form-label required fw-bold fs-6'>
                  Liste Des Produits
                </label>

                {
                  loadingProduct ?
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      // options={options}
                      onChange={handleChangeSelect}
                    />
                    :
                    <span className='indicator-progress fs-4 fw-bold text-primary' style={{ display: 'block' }}>
                      Chargement Des Produits...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                }

              </div>
            </div>

          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>

        </form>
      </div>
    </div>
  )
}

export default AddPack