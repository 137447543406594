import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import {SubCategoryProps, ZoneProps } from '../../../_metronic/helpers/Props'
import { apiUrl, zoneImgUrl } from '../../../_metronic/helpers/Helpers'
import axios from 'axios'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ListZone: React.FC = () => {

  const [zone, setZone] = useState<ZoneProps[]>([])
  const [isloading, setIsLoading] = useState(false)

  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/zones.json`)

      setZone(response.data)
    } catch {
    }
  }
  useEffect(() => {
    fetchData()
  }, [])


  const DeleteZone = async (zoneId: number) => {
    try {
      const response = await axios.delete(`${apiUrl}/zones/${zoneId}`)

      if (response.status === 204) {
        setIsLoading(false)
        toast.success('la Zone est supprimer avec succès');

        // fetchData()
      }
      else {
        toast.error('Erreur lors de la supprission de la zone');
      }
    } catch {
      toast.error('Erreur lors de la communication avec le serveur');

    }
  }


  return (
    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Liste Des Zones</span>
        </h3>
        <div className='card-toolbar'>
          <Link to='/admin/AddZone' className='btn btn-sm btn-light-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Nouveau Categorie
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-300px rounded-start'>Image</th>
                <th className='ps-4 min-w-200px'>Nom de la zone</th>
                <th className='min-w-100px text-end rounded-end pe-5'>Action</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {zone.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-70px'>
                          <img
                            src={`${zoneImgUrl}/${item.picture}`}
                            className='w-100'
                            alt=''
                          />
                        </div>
                      </div>
                    </td>

                    <td>
                      <span className='text-dark fw-bold d-block mb-1 fs-6'>
                        {item.title}
                      </span>
                    </td>
                   
                    <td className='text-end'>
                      <Link to={`/admin/Update-Zone/${item.id}`} className='btn btn-icon btn-light-primary btn-sm me-3'>
                        <i className='bi bi-pen-fill fs-4'></i>
                      </Link>
                      <button onClick={() => DeleteZone(item.id)} className='btn btn-icon btn-light-danger btn-sm'>
                        <i className='bi bi-trash-fill fs-4'></i>
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export default ListZone