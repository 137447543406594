import { FC } from 'react'
import { CardHeaderProps } from '../../../../_metronic/helpers/Props'

const CardHeader: FC<CardHeaderProps> = ({ target, label }) => {
    return (
        <div
            className='card-header border-0 cursor-pointer'
            role='button'
            data-bs-toggle='collapse'
            data-bs-target={`#${target}`}
            aria-expanded='true'
            aria-controls={`${target}`}
        >
            <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>{label}</h3>
            </div>
        </div>
    )
}

export default CardHeader