import React, { createContext, useContext, useState, useEffect } from 'react';
import { CategoryProps, ZoneProps } from '../../_metronic/helpers/Props';
// import { useApiRequest } from '../../_metronic/helpers/ApiRequest';
import axios from 'axios';
import { apiUrl } from '../../_metronic/helpers/Helpers';


const ZoneContext = createContext<{
    zone: ZoneProps[];
}>({
    zone: [],
});

export const useZoneContext = () => useContext(ZoneContext);

export const ZoneProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [zone, setZone] = useState<ZoneProps[]>([]);
    // const apiRequest = useApiRequest()

    const fetchData = async () => {
        try {
          const response = await axios.get(`${apiUrl}/zones`)
    
          setZone(response.data)
          
        } catch {
        }
      }
      useEffect(() => {
        fetchData()
      }, [])

    return (
        <>
            <ZoneContext.Provider value={{ zone }}>
                {children}
            </ZoneContext.Provider>
        </>

    );
};
