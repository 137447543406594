import React from 'react'
import { Modal } from 'react-bootstrap'
import { OrderModalProps } from '../../../_metronic/helpers/Props'
import OrderAdresseItem from '../../../_metronic/helpers/components/OrderAdresseItem'

const OrderAdressesModal: React.FC<OrderModalProps> = ({ order, show, onHide }) => {
  const livraison = order

  const GetAddress = ({ label, address }:any ) => (
    
    <>
      <OrderAdresseItem label='Nom et Prenom' value={`${address?.user.firstname ?? ''} ${address?.user.lastname ?? ''}`} />
      <OrderAdresseItem label='Telephone' value={`${address?.user.phoneNumber ?? ''}`} />
      <OrderAdresseItem label='Adresse' value={`${address?.adresse ?? ''}`} />
     
    </>
  )

  return (
    <Modal show={show} onHide={onHide} centered>
    <Modal.Header closeButton>
      <Modal.Title className='text-info mb-5'>Adresses De Livraison</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      
      {livraison && <GetAddress label='livraison' address={livraison} />}
      {!livraison && <p>Aucune adresse disponible pour cette commande.</p>}
    </Modal.Body>
  </Modal>
  )
}

export default OrderAdressesModal