import React from 'react'
import { Modal } from 'react-bootstrap'
import { productImgUrl, uploadUrl, varianteImgUrl} from '../../../_metronic/helpers/Helpers'
import { OfflineOrderModalProps, ReservationModalProps, ReservationProps } from '../../../_metronic/helpers/Props'
import { formatDate, formatPrice } from '@mbs-dev/react-helpers'


const ReservationDetailsModal: React.FC<ReservationModalProps> = ({ reservation , show, onHide }) => {

  return (
    <Modal show={show} onHide={onHide} centered dialogClassName='order-adresse-modal'>
      <Modal.Header closeButton>
        <Modal.Title className='fs-3'>Liste Des Articles</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-125px rounded-start'>Image</th>
                <th className='ps-4 min-w-125px rounded-start'>Nom Produit</th>
                <th className='min-w-100px'>Quantité</th>
                <th className='ps-4 min-w-100px'>Prix Unitaire</th>
              </tr>
            </thead>
            <tbody>
              {
                reservation?.reservationItem.map((o: any, index: number) =>
                   (
                  
                  <tr key={index}>                    
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-50px'>
                          <img
                            src={`${productImgUrl}/${o.product.picture}`}
                            className='w-100'
                            alt=''
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className='text-dark fw-bold d-block mb-1 fs-6'>
                        {`${o.product ? o.product?.title : o.pack?.title}`}
                      </span>
                      {o.isvariant ?
                        <div className="d-flex alight-items-center">
                          <div className={`subvariant-image-content mx-1 d-flex-center c-pointer selected-border`} >
                            <div className="subvariant-image d-flex-center">
                            <img
                            src={`${varianteImgUrl}/${o.variante.picture}`}
                            className='w-100'
                            alt=''
                          />                             
                          </div>
                          </div>
                          <div className="subvariant-title pt-1 text-info fw-bold">
                            {`${o.variant.reference} ${o.variant.label}`}
                          </div>
                        </div>
                        :
                        null
                      }
                    </td>

                    <td>
                      <span className='text-dark fw-bold d-block mb-1 fs-6'>
                        <span className="text-info">{o.quantity}</span> {`${o.product ? 'Produit' : 'Pack'}`}
                      </span>
                    </td>

                    <td>
                      <span className='text-dark fw-bold d-block mb-1 ps-3 fs-6'>
                        <span className="text-info">{formatPrice(o.product.price)}</span> DH
                      </span>
                    </td>

                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>

        <hr className='hr-tag' />

        <h3 className='text-info mb-5'>Autre Informations :</h3>
        <div className="content">
          {/* <p><span className='ms-5 fs-4 min-w-150px d-inline-block'>Methode de payemet :</span> <span className='fs-5 fw-bold'>{`${reservation?.paymentMethod === 'livraison' ? 'À la livraison' : 'Par Carte bancaire'}`}</span></p> */}
          <p><span className='ms-5 fs-4 min-w-150px d-inline-block'>Reference :</span> <span className='fs-5 fw-bold'>{`${reservation?.reference}`}</span></p>
        </div>

      </Modal.Body>
    </Modal>
  )
}

export default ReservationDetailsModal