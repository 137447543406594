import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { AuthInit } from './modules/auth'
import { CategoriesProvider } from './context/CategoriesContext'
import { SubCategoryProvider } from './context/SubCategoryContext'
import { ParentProvider } from './context/ParentContext'
import { ProductProvider } from './context/ProductContext'
import { ToastContainer } from 'react-toastify'
import { SubCategoriesParentProvider } from './context/SubCategoriesParentContext'
import { ZoneProvider } from './context/ZoneContext'

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <CategoriesProvider>
            <SubCategoryProvider>
              <ParentProvider>
                <ProductProvider>
                  <SubCategoriesParentProvider>
                     <ZoneProvider>
                       <AuthInit>
                        <Outlet />
                        <MasterInit />
                        <ToastContainer />
                      </AuthInit>
                     </ZoneProvider>
                  </SubCategoriesParentProvider>
                </ProductProvider>
              </ParentProvider>
            </SubCategoryProvider>
          </CategoriesProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export { App }
