import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { KTSVG } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import {SubCategoryProps } from '../../../_metronic/helpers/Props'
import { apiUrl, subcategorieImgUrl } from '../../../_metronic/helpers/Helpers'
import axios from 'axios'
import { useSubCategoryContext } from '../../context/SubCategoryContext'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useApiRequest from '@mbs-dev/api-request'
import { Pagination } from '@mui/material';
import * as Yup from 'yup'


const ListSubCategory: React.FC = () => {

  const [sub_categories, setSubCategories] = useState<SubCategoryProps[]>([])
  const [isloading, setIsLoading] = useState(false)
  const { apiRequest } = useApiRequest()
  const [isLoadingData, setIsLoadingData] = useState(false)
  const { subcategories } = useSubCategoryContext()


  // Pagination state
  const [isFilter, setIsFilter] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [filterCurrentPage, setFilterCurrentPage] = useState(1)
  const [filterTotalPages, setFilterTotalPages] = useState(1)
  const [defaultPage, setDefaultPage] = useState(1)

  const Schema = Yup.object().shape({
    parent: Yup.string().oneOf(
      subcategories.map((subcategory) => subcategory.id.toString()),
      'Sélectionnez une sous catégorie valide'
    ),
   
  })

  
  const fetchData = async () => {
    try {
      const response = await apiRequest({
        route: `${apiUrl}/sub_categories.json`,
        method: 'GET',
      })

      setSubCategories(response.data)
    } catch {
    }
  }
  useEffect(() => {
    fetchData()
  }, [])

  const formik = useFormik({
    initialValues: {
      parent: "",
    },

    validationSchema: Schema,
    onSubmit: async (values) => {
      setIsLoading(true)

      const formData = new FormData()
      formData.append('parent', values.parent)
      const response = await axios.post(`${apiUrl}/sub_categories`, formData)
    try{
      if (response.status === 200) {
        setIsLoading(false)
        toast.success('Sous Categorie est enregistré avec succès');
      }
      else {
        toast.error('Erreur lors de l\'enregistrement de Sous Categorie');
      }
    } catch (error) {
      toast.error('Erreur lors de la communication avec le serveur');
    }



    },
  })

  const DeleteSubCategorie = async (subcategoriesId: number) => {
    try {
      const response = await axios.delete(`${apiUrl}/sub_categories/${subcategoriesId}`)

      if (response.status === 204) {
        setIsLoading(false)
        toast.success('Sous Categorie est supprimer avec succès');

        // fetchData()
      }
      else {
        toast.error('Erreur lors de la supprission de Sous Categorrie');
      }
    } catch {
      toast.error('Erreur lors de la communication avec le serveur');

    }
  }
  function handlePageChange(event: any, value: any) {
    setCurrentPage(value)
    localStorage.setItem('storedProdPage', String(value))
  }

  function handleFilterPageChange(event: any, value: any) {
    setFilterCurrentPage(value)
    localStorage.setItem('storedProdPage', String(value))
  }


  return (
    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Liste Des  Sous Categorie</span>
        </h3>
        <div className='card-toolbar'>
          <Link to='/admin/AddSubCategory' className='btn btn-sm btn-light-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Nouveau Categorie
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-200px rounded-start'>Image</th>
                <th className='ps-4 min-w-100px'>Nom sous categorie</th>
                <th className='ps-4 min-w-100px'>Categorie</th>
                <th className='ps-4 min-w-100px'>Parent</th>
                <th className='min-w-100px text-end rounded-end pe-5'>Action</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {sub_categories.map((item) => 
              {
                return (
                  <tr key={item.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-70px'>
                          <img
                            src={`${subcategorieImgUrl}/${item.picture}`}
                            className='w-100'
                            alt=''
                          />
                        </div>
                      </div>
                    </td>

                    <td>
                      <span className='text-dark fw-bold d-block mb-1 fs-6'>
                        {item.title}
                      </span>
                    </td>

                    <td>
                      <span className='text-dark fw-bold d-block mb-1 fs-6'>
                        {item.category? item.category.title: " - "}
                      </span>
                    </td>
                  

                  <td>
                        {
                          item.parent ?
                            <span className='text-dark fw-bold d-block mb-1 fs-6'>
                              {item.parent.title}
                            </span>
                            :
                            <span className='badge badge-light-dark fs-8 p-2'>
                              Non ajouté
                            </span>
                        }
                      </td>
                    
                   
                    <td className='text-end'>
                      <Link to={`/admin/Update-SubCategory/${item.id}`} className='btn btn-icon btn-light-primary btn-sm me-3'>
                        <i className='bi bi-pen-fill fs-4'></i>
                      </Link>
                      <button onClick={() => DeleteSubCategorie(item.id)} className='btn btn-icon btn-light-danger btn-sm'>
                        <i className='bi bi-trash-fill fs-4'></i>
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      {
        isFilter
          ? <Pagination
            count={filterTotalPages}
            page={filterCurrentPage}
            color='primary'
            onChange={handleFilterPageChange}
            className='d-flex justify-content-center'
          />
          :
          <Pagination
            count={totalPages}
            page={currentPage}
            color='primary'
            onChange={handlePageChange}
            className='d-flex justify-content-center'
          />
      }
      <div className={`card mt-2 d-none`}>

<div className='card-body p-1'>
  <div className="container">
    <div className="row justify-content-center align-items-center">

      <div className="col-4 money">
        <input
          type="text"
          placeholder='Numéro de page'
          min={1}
          defaultValue={defaultPage}
          className='form-control form-control-lg form-control-dark'
          onChange={(e) => {
            const inputText = e.target.value
            const numbersOnly = inputText.replace(/\D/g, '')
            if (Number(numbersOnly) !== 0 && numbersOnly.length > 0 && Number(numbersOnly) <= Number(totalPages)) {
              setCurrentPage(Number(numbersOnly))
              if (isFilter) {
                setFilterCurrentPage(Number(numbersOnly))
              }
              setDefaultPage(Number(numbersOnly))
              localStorage.setItem('storedProdPage', numbersOnly)
            }
            e.target.value = numbersOnly
          }} />
      </div>

    </div>
  </div>
</div>

</div>
    </div>
  )
}

export default ListSubCategory