import React, { createContext, useContext, useState, useEffect } from 'react';
import { CategoryProps, ProductProps } from '../../_metronic/helpers/Props';
// import { useApiRequest } from '../../_metronic/helpers/ApiRequest';
import axios from 'axios';
import { apiUrl } from '../../_metronic/helpers/Helpers';


const ProductContext = createContext<{
    product: ProductProps[];
}>({
    product: [],
});

export const useProductContext = () => useContext(ProductContext);

export const ProductProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [product, setProducts] = useState<ProductProps[]>([]);
    // const apiRequest = useApiRequest()

    const fetchData = async () => {
        try {
          const response = await axios.get(`${apiUrl}/products`)
    
          setProducts(response.data)
          
        } catch {
        }
      }
      useEffect(() => {
        fetchData()
      }, [])

    return (
        <>
            <ProductContext.Provider value={{ product }}>
                {children}
            </ProductContext.Provider>
        </>

    );
};
