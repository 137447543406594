import React, { createContext, useContext, useState, useEffect } from 'react';
import { SubCategoryProps } from '../../_metronic/helpers/Props';
// import { useApiRequest } from '../../_metronic/helpers/ApiRequest';
import axios from 'axios';
import { apiUrl } from '../../_metronic/helpers/Helpers';


const ParentContext = createContext<{
    subcategories: SubCategoryProps[];
}>({
    subcategories: [],
});

export const useParentContext = () => useContext(ParentContext);

export const ParentProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [subcategories, setCategories] = useState<SubCategoryProps[]>([]);
    // const apiRequest = useApiRequest()

    const fetchData = async () => {
        try {
          const response = await axios.get(`${apiUrl}/listsubcategory`)
    
          setCategories(response.data)
          
        } catch {
        }
      }
      useEffect(() => {
        fetchData()
      }, [])

    return (
        <>
            <ParentContext.Provider value={{ subcategories }}>
                {children}
            </ParentContext.Provider>
        </>

    );
};
