import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import ListCategory from '../pages/Category/ListCategory'
import AddProduct from '../pages/Product/AddProduct'
import ListProduct from '../pages/Product/ListProduct'
import AddVariante from '../pages/Variante/AddVariante'
import ListVariante from '../pages/Variante/ListVariante'
import ListSubCategory from '../pages/SubCategory/ListSubCategory'
import AddSubCategory from '../pages/SubCategory/AddSubCategory'
import AddCategorie from '../pages/Category/AddCategory'
import AddCategory from '../pages/Category/AddCategory'
import UpdateCategory from '../pages/Category/UpdateCategory'
import UpdateProduct from '../pages/Product/UpdateProduct'
import UpdateVariante from '../pages/Variante/UpdateVariante'
// import ListOrder from '../pages/Order/ListOrder'
import ListUser from '../pages/User/ListAdmin'
import AddUser from '../pages/User/AddUser'
import UpdateUser from '../pages/User/UpdateUser'
import ListAdmin from '../pages/User/ListAdmin'
import ListClient from '../pages/User/ListClient'
import AddZone from '../pages/Zone/AddZone'
import ListZone from '../pages/Zone/ListZone'
import ListTable from '../pages/Table/ListTable'
import AddTable from '../pages/Table/AddTable'
import ListReservation from '../pages/Reservation/ListReservation'
import ListOrder from '../pages/Order/ListOrder'
import ListOrderOffline from '../pages/OrderOffline/ListOrderOffline'
import AddSlider from '../pages/Slider/AddSlider'
import UpdateTable from '../pages/Table/UpdateTable'
import UpdateZone from '../pages/Zone/UpdateZone'
import ListReservationOffline from '../pages/ReservationOffline/ListReservationOffline'
import ListSlider from '../pages/Slider/ListSlider'
import UpdateSlider from '../pages/Slider/UpdateSlider'
import UpdateSubCategory from '../pages/SubCategory/UpdateSubCategory'
import AddPack from '../pages/Pack/AddPack'
// import UpdateCategory from '../pages/Category/UpdateCategory'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />

        {/* Categoery  */}
        <Route
          path="/admin/AddCategory"
          element={<AddCategory/>}
        />
        <Route
          path="/admin/update-category/:id"
          element={<UpdateCategory />}
        />
        <Route
          path="/admin/ListCategory"
          element={<ListCategory />}
        />

        {/* Slider  */}
        <Route
          path="/admin/Add-Slider"
          element={<AddSlider/>}
        />
        
        <Route
          path="/admin/update-slider/:id"
          element={<UpdateSlider />}
        />

        <Route
          path="/admin/List-Slider"
          element={<ListSlider />}
        />

        {/* Categoery  */}
        <Route
          path="/admin/AddCategory"
          element={<AddCategory/>}
        />
        <Route
          path="/admin/update-category/:id"
          element={<UpdateCategory />}
        />
        <Route
          path="/admin/ListCategory"
          element={<ListCategory />}
        />

        {/* sous category  */}
        <Route
          path="/admin/AddSubCategory"
          element={<AddSubCategory />}
        />
        <Route
          path="/admin/Update-SubCategory/:id"
          element={<UpdateSubCategory/>}
        />

        <Route
          path="/admin/ListSubCategory"
          element={<ListSubCategory />}
        />

        {/* Product  */}
        <Route
          path="/admin/AddProduct"
          element={<AddProduct />}
        />

        <Route
          path="/admin/Update-Product/:id"
          element={<UpdateProduct/>}
        />

        <Route
          path="/admin/ListProduct"
          element={<ListProduct />}
        />

        {/* Pack  */}
        <Route
          path="/admin/Add-Pack"
          element={<AddPack />}
        />

        {/* <Route
          path="/admin/Update-Pack/:id"
          element={<UpdateProduct/>}
        />

        <Route
          path="/admin/ListProduct"
          element={<ListProduct />}
        /> */}

          {/* Zone  */}
          <Route
          path="/admin/Add-Zone"
          element={<AddZone />}
        />

        <Route
          path="/admin/List-Zone"
          element={<ListZone/>}
        />

        <Route
          path="/admin/Update-Zone/:id"
          element={<UpdateZone/>}
        />

        {/* Table  */}
        <Route
          path="/admin/ListTable"
          element={<ListTable />}
        />
        <Route
          path="/admin/AddTable"
          element={<AddTable />}
        />
        <Route
          path="/admin/update-Table/:id"
          element={<UpdateTable/>}
        />
       
        {/* Reservation  */}
        <Route
          path="/admin/ListReservation"
          element={<ListReservation/>}
        />

        {/* ReservationOffline  */}
        <Route
          path="/admin/List-ReservationOffline"
          element={<ListReservationOffline/>}
        />
        


        {/* Variante  */}
        <Route
          path="/admin/AddVariante"
          element={<AddVariante />}
        />

        <Route
          path="/admin/Update-Variante/:id"
          element={<UpdateVariante />}
        />

        <Route
          path="/admin/ListVariante"
          element={<ListVariante />}
        />

        <Route
          path="/admin/ListOrder"
          element={<ListOrder />}
        />
        <Route
          path="/admin/List-OrderOffline"
          element={<ListOrderOffline />}
        />
        <Route
          path="/admin/update-order/:id"
          element={<ListOrder />}
        />

        <Route
          path="/admin/List-Admin"
          element={<ListAdmin />}
        />
        <Route
          path="/admin/List-Client"
          element={<ListClient />}
        />
        <Route
          path="/admin/Update-User/:id"
          element={<UpdateUser />}
        />
        <Route
          path="/admin/Add-User"
          element={<AddUser />}
        />









        <Route
          path='builder'
          element={
            <SuspensedView>
              <BuilderPageWrapper />
            </SuspensedView>
          }
        />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
