import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import {
  MixedWidget11,
  ListsWidget6,
  StatisticsWidget5,
} from '../../../_metronic/partials/widgets'
import { useEffect, useState } from 'react'
import useApiRequest from '@mbs-dev/api-request'
import { apiUrl } from '../../../_metronic/helpers/Helpers'
import LoadingScreen from '../../../_metronic/helpers/LoadingScreen'

const DashboardPage = () => {

  const { apiRequest } = useApiRequest()
  const [todaySalles, setTodaySalles] = useState<any>(null)
  const [AnnualSalles, setAnnualSalles] = useState<any>(null)
  const [totalOrders, setTotalOrders] = useState<any>(null)
  const [productsSold, setProductsSold] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(false)

  const getSAlles = async () => {
    try {
      setIsLoading(true)
      const todayResponse = await apiRequest({
        route: `${apiUrl}/today-salles`,
        method: 'GET',
      })
      const annualResponse = await apiRequest({
        route: `${apiUrl}/annual-salles`,
        method: 'GET',
      })
      const ordersResponse = await apiRequest({
        route: `${apiUrl}/today-orders`,
        method: 'GET',
      })
      const productsSoldResponse = await apiRequest({
        route: `${apiUrl}/today-products-sold`,
        method: 'GET',
      })

      setTodaySalles(todayResponse.data.totalSalles)
      setAnnualSalles(annualResponse.data.totalSalles)
      setTotalOrders(ordersResponse.data.totalOrders)
      setProductsSold(productsSoldResponse.data.productsSold)
      setIsLoading(false)

    } catch {
      console.error('Error fetching product data')
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getSAlles()
  }, [])

  return (
    <>
      {isLoading ?
        <LoadingScreen/>
        :
        <>
          <div className='row g-5 g-xl-8'>
            <div className='col-xl-3 my-2'>
              <StatisticsWidget5
                className='card-xl-stretch mb-xl-8'
                svgIcon='/media/icons/duotune/finance/fin003.svg'
                color='body-white'
                iconColor='dark'
                title='Revenus Quotidiens'
                description={`${todaySalles === null || todaySalles === 0 ? todaySalles : parseFloat(todaySalles).toFixed(2)} DH`}
                titleColor='gray-900'
                descriptionColor='dark'
              />
            </div>

            <div className='col-xl-3 my-2'>
              <StatisticsWidget5
                className='card-xl-stretch mb-xl-8'
                svgIcon='/media/icons/duotune/ecommerce/ecm002.svg'
                color='secondary'
                iconColor='dark'
                title='Commandes Du Jour'
                description={`${totalOrders} Commande`}
                titleColor='dark'
                descriptionColor='dark'
              />
            </div>

            <div className='col-xl-3 my-2'>
              <StatisticsWidget5
                className='card-xl-stretch mb-xl-8'
                svgIcon='/media/icons/duotune/general/gen008.svg'
                color='primary'
                iconColor='white'
                title='Produits Vendus'
                description={`${productsSold} Produits`}
                titleColor='white'
                descriptionColor='white'
              />
            </div>

            <div className='col-xl-3 my-2'>
              <StatisticsWidget5
                className='card-xl-stretch mb-5 mb-xl-8'
                svgIcon='/media/icons/duotune/arrows/arr070.svg'
                color='dark'
                iconColor='gray-100'
                title='Revenus Annuels'
                description={`${AnnualSalles === null || AnnualSalles === 0 ? AnnualSalles : parseFloat(AnnualSalles).toFixed(2)} DH`}
                titleColor='gray-100'
                descriptionColor='gray-100'
              />
            </div>
          </div>

          <div className='  '>
            <MixedWidget11
              className='card-xxl-stretch-50 mb-5 mb-xl-8'
              chartColor='primary'
              chartHeight='400px'
            />
          </div>

          <div className=''>
            <ListsWidget6 className='card-xl-stretch mb-5 mb-xl-8' />
          </div>
        </>
      }
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }