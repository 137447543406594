import React from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { formatDate, formatPrice } from '@mbs-dev/react-helpers'
import { OfflineOrderProps, offlineOrderItemsProps } from '../../../_metronic/helpers/Props'
import './OfflineOrderItem.css'


interface orderOffline {
  orderOffline: OfflineOrderProps
}

const offlineOrderFacture: React.FC<orderOffline> = (({ orderOffline }) => {

  return (
    <div className="bh-container d-flexbh-justify-content-center">
      <div className={`card bh-card hbcontainer d-flex bh-justify-content-center `}>
        <div className={`bh-card-header border-0 pt-5 ${orderOffline?.paymentMethode === 'carte' ? 'bh-card-header-p2' : 'bh-card-header-p1'}`}>
          <span className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-2 mb-1' style={{color:"green"}}>Bon De Commmande</span>
          </span>
        </div>
        <div className='card-body py-3'>
          <div className="hbcontainer">

            <div className="row bh-m-row">
              <div className="bh-col-1 col-6 bh-logo-container">
                <img src={toAbsoluteUrl('/media/logos/brownLogo.png')} alt='' className='bh-logo' />
              </div>
              <div className={`col-6 d-flex bh-justify-content-end align-items-`}>
                <div className="bh-order-info">
                  <div className={`bh-cmd-info mb-5 ${orderOffline?.paymentMethode === 'carte' ? '' : 'bh-cmd-info-p'}`}>
                    <p className='bh-p-1'>
                      <span className='fw-bold bh-info-text'>Numero de commande : </span>
                      <span className='bh-info-value'>{orderOffline?.reference} </span>
                    </p>
                    <p className='bh-p-1'>
                      <span className='fw-bold bh-info-text'>Date Commande : </span>
                      <span className='bh-info-value'>{formatDate(orderOffline?.createdAt)}</span>
                    </p>
                    <p className='bh-p-1'>
                      <span className='fw-bold bh-info-text'>Methode de payemet : </span>
                      <span className='bh-info-value'>{orderOffline?.paymentMethode === 'carte' ? 'Par Carte bancaire' : 'À la livraison'}</span>
                    </p>
                  </div>

                </div>
              </div>
            </div>

            <div className="row row-user">
              <hr className='hrtag' />
              <div className="col-12  bh-m-client ">
                <div className="bh-user-info mt-">
                  <p className='bh-p-2'>
                    <span className='fw-bold bh-info-text'>Adresses de livraison : </span>
                    {/* <span className='bh-info-value'>{`${offlineorder?.livraisonAdresse?.rueInfo}, ${offlineorder?.livraisonAdresse?.ville}, ${order?.livraisonAdresse?.region} | ${order?.livraisonAdresse?.codepostal}`}</span> */}
                  </p>
                </div>
              </div>

            </div>


          </div>
          {/* end container */}
          {/* <hr className='hrtag' /> */}
          <div className='table-responsive m-table'>
            <table className='table align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  {/* <th className='ps-4  rounded-star tb-radius bh-t-dark'>Image</th> */}
                  <th className='ps-4  rounded-star tb-radius bh-t-dark'>Nom Produit</th>
                  <th className='bh-t-center bh-t-dark'>Quantité</th>
                  <th className='ps-4 bh-txt-end bh-t-dark min-with-th-pu'>Prix Unitaire</th>
                  <th className='pl-0  bh-txt-end bh-t-dark total-min-w'>Total</th>
                </tr>
              </thead>
              <tbody>
                {orderOffline?.orderOfflineItem.map((o: offlineOrderItemsProps, index: number) => (
                  <tr key={o.id} className='item-row'>

                    <td className={`bh-p-0`}>
                      <span className='text-dark d-block ml-13 fs-order'>
                        {`${o.product ? o.product?.title : null} - `}
                        {o.isvariant ? `${o.variant.title}` : null}
                      </span>
                    </td>

                    <td className='bh-t-center bh-p-0'>
                      <span className='fw--bold text-dark d-block fs-5'>
                        {o.quantity}
                      </span>
                    </td>

                    <td className='bh-t-end hbp-0 item-td'>
                      <span className='fw--bold text-dark d-block fs-7'>
                        {formatPrice(String(o.product?.price ))}

                      </span>
                    </td>

                    <td className='bh-t-end hbp-0 item-td'>
                      <span className='fw-bold text-dark d-block fs-7'>
                        {formatPrice(String(o.price * o.quantity))}
                      </span>
                    </td>

                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>


          <div className="hr-container">
            <div className="row">
              <hr className='hrtag' />

              <div className="col-12 d-flex bh-justify-content-end">
                <p className='p-w'>
                  <span className='fwbold bh-price-text'>Total : </span>
                  <span className='bh-price-value fw-bold'>{formatPrice(String(orderOffline?.totalPrice))} MAD</span>
                </p>
              </div>

              {/* <div className="col-12 d-flex bh-justify-content-end">
                <p className='p-w'>
                  <span className='fwbold bh-price-text'>Frait de livraison : </span>
                  <span className='bh-price-value fw-bold'>{Number(orderOffline?.totalPrice) > 300 ? 'Gratuit' : '50 MAD'}</span>
                </p>
              </div> */}

              <div className="col-12 d-flex bh-justify-content-end">
                <p className='p-w bh-total-price-p'>
                  <span className='fwbold bh-total-price'>Total à payer  : </span>
                  <span className='bh-price-value fw-bold'>{Number(orderOffline?.totalPrice) > 300 ? formatPrice(String(orderOffline?.totalPrice)) : formatPrice(String(orderOffline?.totalPrice + 50))} MAD</span>
                </p>
              </div>


            </div>

          

          </div>
        </div>

      </div>

      <div className='cardfooter bh-card-footer bh-align-items-end fixedbottom d-flex cbh-justify-content-end py-6 px-9'>
        <div className="container">
          <hr className='hrtag' />
          <div className="row">
            <p className='bh-infotext bh-data  d-flex bh-justify-content-center bh-mb-10'>

              <span className='bh-data-text fw-bold'>I.C.E N° :</span>
              <span className='bh-data-value fw-bold'>001122334455667</span>

              <span className='separate fw-bold'> - </span>

              <span className='bh-data-text fw-bold'>RC N° :</span>
              <span className='bh-data-value fw-bold'>098765</span>

              <span className='separate fw-bold'> - </span>

              <span className='bh-data-text fw-bold'>Tel :</span>
              <span className='bh-data-value fw-bold'>0600456348</span>
            </p>
          </div>

          <div className="row">
            <p className="bh-infotext bh-data  d-flex bh-justify-content-center">
              <span className='bh-data-text fw-bold'>Email :</span>
              <span className='bh-data-value fw-bold'>Demo@fcpo.agencya</span>

              <span className='separate fw-bold'> - </span>

              <span className='bh-data-text fw-bold'>Adresse :</span>
              <span className='bh-data-value fw-bold'>Casablanca, bd abdelmoumen, abdelmoumen center angle </span>

            </p>
          </div>
        </div>
      </div>
    </div>
  )
})

export default offlineOrderFacture