import React from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { formatDate, formatPrice } from '@mbs-dev/react-helpers'
import { OrderItemProps, OrderProps } from '../../../_metronic/helpers/Props'

import './OrderItem.css'
import { varianteImgUrl } from '../../../_metronic/helpers/Helpers'
interface Order {
  order: OrderProps
}

const OrderFacture: React.FC<Order> = (({ order }) => {
  return (
    <div className="bh-container d-flexbh-justify-content-center">
      <div className={`card bh-card hbcontainer d-flex bh-justify-content-center `}>
        <div className={`bh-card-header border-0 pt-5 ${order?.paymentMethode === 'carte' ? 'bh-card-header-p2' : 'bh-card-header-p1'}`}>
          {/* <span className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-2 mb-1' style={{color:"black"}}>Bon De Commmande </span>
          </span> */}
        </div>
        <div className='card-body py-3'>
          <div className="hbcontainer">

            <div className="row bh-m-row">
              {/* <div className="bh-col-1 col-12 bh-logo-container">
                <img src={toAbsoluteUrl('/media/logos/brownLogo.png')} alt='' className='bh-logo' />
              </div> */}
              <div className={`col-12 d-flex bh-justify-content-end align-items-`}>
                <div className="bh-order-info">
                  <div className={`bh-cmd-info mb-5 ${order?.paymentMethode === 'carte' ? '' : 'bh-cmd-info-p'}`}>
                    <p className='bh-p-1'>
                      <span className='fw-bold'>Numero de commande : </span>
                      <span className='bh-info-value'>{order?.reference} </span>
                    </p>
                    <p className='bh-p-1'>
                      <span className='fw-bold'>Date Commande : </span>
                      <span className='bh-info-value'>{formatDate(order?.createdAt)}</span>
                    </p>
                    <p className='bh-p-1'>
                      <span className='fw-bold'>Methode de payemet : </span>
                      <span className='bh-info-value'>{order?.paymentMethode === 'carte' ? 'Par Carte bancaire' : 'À la livraison'}</span>
                    </p>
                  </div>

                </div>
              </div>
            </div>

            <div className="row row-user">
              <hr className='hrtag' />
              <div className="col-12  bh-m-client ">
                <div className="bh-user-info mt-">
                  <p className='bh-p-2'>
                    <span className='fw-bold bh-info-text'>Adresses de livraison : </span>
                    <span className='bh-info-value'>{`${order?.adresse}`}</span>
                  </p>
                </div>
              </div>

            </div>


          </div>
          {/* end container */}
          {/* <hr className='hrtag' /> */}
          <div className='table-responsive m-table'>
            <table className='table align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  {/* <th className='ps-4  rounded-star tb-radius bh-t-dark'>Image</th> */}
                  <th className='ps-4  rounded-star tb-radius bh-t-dark'>Nom Produit</th>
                  <th className='bh-t-center bh-t-dark'>Quantité</th>
                  <th className='bh-txt-end bh-t-dark min-with-th-pu'>Prix Unitaire</th>
                  <th className='bh-txt-end bh-t-dark total-min-w'>Total</th>
                </tr>
              </thead>
              <tbody>
                {order?.orderItem.map((o: OrderItemProps, index: number)  => (
                
                  <tr key={o.id} className='item-row'>
                    
                    <td>
                    {o.product && !o.pack && !o.variant && (
                        <span className='fw-bold d-block ps-4 fs-6'>
                          {o.product.title}
                        </span>
                      )}
                      {o.pack && (
                        <span className='fw-bold ps-4 d-block fs-6'>
                          {o.pack.title}
                        </span>
                      )}
                      {o.variant && (
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-50px">
                            <img
                              src={o.variant.picture ? `${varianteImgUrl}/${o.variant.picture}` : ''}
                              className='w-100'
                              alt=''
                            />
                          </div>
                          <div className="subvariant-title pt-1 text-info ps-4 fw-bold">
                            {`${o.variant.title}`}
                          </div>
                        </div>
                      )}
                    </td>


                    <td className='bh-t-center bh-p-0'>
                      <span className='fw--bold d-block fs-6'>
                        {o.quantity}
                      </span>
                    </td>

                    
                    <td>
                      <span className='fw-bold d-block fs-6'>
                        <span className="text-info">{formatPrice(String(o.product?.price|| o.variant?.price || o.pack?.price))}</span> DH
                      </span>
                    </td>

                    <td>
                      <span className='fw-bold d-block fs-6'>
                        <span className="text-info">{formatPrice(String(o.product?.price* o.quantity|| o.variant?.price* o.quantity || o.pack?.price* o.quantity))}</span> DH
                      </span>
                    </td>


                   
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>


           <div className="hr-container">
            <div className="row">
              <hr className='hrtag' />

              <div className="col-12 d-flex bh-justify-content-end">
                <p className='p-w'>
                  <span className='fwbold bh-price-text'>Total : </span>
                  <span className='bh-price-value fw-bold'>{formatPrice(String(order?.totalePrice))} MAD</span>
                </p>
              </div>

              <div className="col-12 d-flex bh-justify-content-end">
                <p className='p-w'>
                  <span className='fwbold bh-price-text'>Frait de livraison : </span>
                  <span className='bh-price-value fw-bold'>{Number(order?.totalePrice) > 300 ? 'Gratuit' : '50 MAD'}</span>
                </p>
              </div>

              <div className="col-12 d-flex bh-justify-content-end">
                <p className='p-w bh-total-price-p'>
                  <span className='fwbold bh-total-price'>Total à payer : </span>
                  <span className='bh-price-value fw-bold'>{Number(order?.totalePrice) > 300 ? formatPrice(String(order?.totalePrice)) : formatPrice(String(order?.totalePrice + 50))} MAD</span>
                </p>
              </div>


            </div>

          

          </div> 
        </div>

      </div>

      <div className='cardfooter bh-card-footer bh-align-items-end fixedbottom d-flex cbh-justify-content-end py-6 px-9'>
        <div className="container">
          <hr className='hrtag' />
          <div className="row">
            <p className='bh-infotext bh-data  d-flex bh-justify-content-center bh-mb-10'>

              <span className='bh-data-text fw-bold'>I.C.E N° :</span>
              <span className='bh-data-value fw-bold'>001122334455667</span>

              <span className='separate fw-bold'> - </span>

              <span className='bh-data-text fw-bold'>RC N° :</span>
              <span className='bh-data-value fw-bold'>098765</span>

              <span className='separate fw-bold'> - </span>

              <span className='bh-data-text fw-bold'>Tel :</span>
              <span className='bh-data-value fw-bold'>0600456348</span>
            </p>
          </div>

          <div className="row">
            <p className="bh-infotext bh-data  d-flex bh-justify-content-center">
              <span className='bh-data-text fw-bold'>Email :</span>
              <span className='bh-data-value fw-bold'>oxford@fcpo.agencya</span>

              <span className='separate fw-bold'> - </span>

              <span className='bh-data-text fw-bold'>Adresse :</span>
              <span className='bh-data-value fw-bold'>Casablanca, bd abdelmoumen, abdelmoumen center angle </span>

            </p>
          </div>
        </div>
      </div>
    </div>
  )
})

export default OrderFacture