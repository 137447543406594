import React, { useState, ChangeEvent, useEffect } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import axios from 'axios'
import { apiUrl } from '../../../_metronic/helpers/Helpers'
import { useCategoriesContext } from '../../context/CategoriesContext'
import { useSubCategoryContext } from '../../context/SubCategoryContext'
import {compressAndAppendImage} from '@mbs-dev/react-image-compressor';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import slugify from 'slugify';
import { SubCategoryProps } from '../../../_metronic/helpers/Props'




const AddSubCategory: React.FC = () => {

  const [loading, setIsLoading] = useState(false)
  const { categories } = useCategoriesContext()
  const { subcategories } = useSubCategoryContext()
  const [imageFile, setimageFile] = useState<File>()
  const [filteredSubcategories, setFilteredSubcategories] = useState<SubCategoryProps[]>([])




  const Schema = Yup.object().shape({
    title: Yup.string().required('Le nom de categorie est obligatoire'),
    picture: Yup.string().required("L'image de categorie est obligatoire"),
    category: Yup.string().oneOf(
      categories.map((category) => category.id.toString()),
      'Sélectionnez une catégorie valide'
    ),
    parent: Yup.string().oneOf(
      subcategories.map((subcategory) => subcategory.id.toString()),
      'Sélectionnez une sous catégorie valide'
    ),
   
  })

  const formik = useFormik({
    initialValues: {
      title: "",
      picture: "",
      parent: "",
      category:""

    },


    validationSchema: Schema,
    onSubmit: async (values) => {
      setIsLoading(true)

      const formData = new FormData()
      formData.append('title', values.title)
      formData.append('parent', values.parent)
      formData.append('category', values.category)
      const imagefileFieldName = 'picture'
      const quality = 0.4
      await compressAndAppendImage(values.picture as unknown as File, formData,'picture',0.4)
      const slug = generateSlug(values.title)
      formData.append('slug', slug)
      
      // const img = await compressAndAppendImage(values.picture, formData,imagefileFieldName,quality)

      // console.log(img);
      // setIsLoading(false)
      // const checkSlug = generateSlug(values.title)

      const response = await axios.post(`${apiUrl}/sub_categories`, formData)
    try{
      if (response.status === 200) {
        setIsLoading(false)
        toast.success('Sous Categorie est enregistré avec succès');
      }
      else {
        toast.error('Erreur lors de l\'enregistrement de Sous Categorie');
      }
    } catch (error) {
      toast.error('Erreur lors de la communication avec le serveur');
    }



    },
  })
  

  function generateSlug(inputString: string): string {
    let string = inputString.replace(/&/g, 'et').replace(/'|’/g, '-').replace('$', '');

    const transliterated = slugify(string, {
        lower: true,
        remove: /[^a-zA-Z0-9 -]/g,
    });

    const slug = transliterated.replace(/ /g, '-').replace(/-+/g, '-').replace(/^-+|-+$/g, '');

    return slug;
}

useEffect(() => {
  const selectedCategory = categories.find(category => category.id.toString() === formik.values.category)
  setFilteredSubcategories(selectedCategory ? selectedCategory.subCategories : [])
}, [formik.values.category, categories])



  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#product'
        aria-expanded='true'
        aria-controls='product'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Ajouter Une Sous Categorie</h3>
        </div>
      </div>

      <div id='product' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>

          <div className='card-body border-top p-9'>

            <div className='row mb-6'>
              <div className='col-lg-12'>
                <div className='row'>
                  <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Nom de sous categorie</label>

                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Nom de sous categorie'
                      {...formik.getFieldProps('title')}
                    />
                    {formik.touched.title && formik.errors.title && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.title}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>picture</label>

                    <input
                      type="file"
                      name="picture"
                      accept=".png, .jpg, .jpeg, .webp"
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {

                        if (e.target.files && e.target.files[0]) {
                          // setFile(URL.createObjectURL(e.target.files[0]))
                          // setpicture(e.target.files[0])
                          formik.setFieldValue('picture', e.target.files[0])
                        }
                      }} />
                    {formik.touched.picture && formik.errors.picture && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.picture}</div>
                      </div>
                    )}


                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Categories</label>
                <select
                  className="form-select"
                  value={formik.values.category}
                  onChange={(event) => {
                    formik.setFieldValue('category', event.target.value)
                  }}
                >
                  <option value="">Sélectionnez une catégorie</option>
                  {categories.map((categorie, index) => (
                    <option key={index} value={categorie.id}>
                      {categorie.title}
                    </option>
                  ))}
                </select>
                {formik.touched.category && formik.errors.category && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.category}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Parent</label>
                <select
                  className="form-select"
                  value={formik.values.parent}
                  onChange={(event) => {
                    formik.setFieldValue('parent', event.target.value)
                  }}
                >
                  <option value="">Sélectionnez un parent</option>
                  {filteredSubcategories.map((subcategory, index) => (
                    <option key={index} value={subcategory.id}>
                      {subcategory.title}
                    </option>
                  ))}
                </select>
                {formik.touched.parent && formik.errors.parent && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.parent}</div>
                  </div>
                )}
              </div>
            </div>

                  
          
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>

        </form>
      </div>
    </div>
  )
}

export default AddSubCategory