export const initUpdateAdminInfo = {
    lastName: "",
    firstName: "",
    telephone: "",
    email: "",
}

export const initUpdateAdminPassword = {
    password: "",
    newpassword: "",
    confirmPassword: "",
}

export const initCategory = {
    title: "",
    description: "",
    parent: "",
    picture: "",
}

export const initSubcategory = {
    title: "",
    picture: "",
}