import React, { useState, useEffect, ChangeEvent } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { apiUrl ,productImgUrl } from '../../../_metronic/helpers/Helpers'
import { ProductProps} from '../../../_metronic/helpers/Props'
import {compressAndAppendImage} from '@mbs-dev/react-image-compressor'
import { useCategoriesContext } from '../../context/CategoriesContext'
import { useSubCategoryContext } from '../../context/SubCategoryContext'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import slugify from 'slugify';
import { useSubCategoriesParentContext } from '../../context/SubCategoriesParentContext'



const UpdateProduct: React.FC = () => {

  const [isloading, setIsLoading] = useState(false)
  const [isSubloading, setIsSubLoading] = useState(false)

  const { id } = useParams()
  const { categories } = useCategoriesContext()
  const { subCategoriesParent } = useSubCategoriesParentContext()
  const [newImage, setNewImage] = useState<File | null>(null)
  const [product, setProducts] = useState<ProductProps>()
  const [SubExist, setSubExist] = useState(false)
  const [parentTitle, setParentTitle] = useState()
  const [imagefile, setImage] = useState<File | any>(null)
  const [disponibiliteState, setDisponibiliteState] = useState(1)
  const [bestsellerState, setBestsellerState] = useState(0)
  const [latestState, setLatestState] = useState(0)   

  const [file, setFile] = useState<string>("")


  const Schema = Yup.object().shape({
    title: Yup.string(),
    picture: Yup.string(),
    price: Yup.number(),
    description: Yup.string(),
    ingrediant: Yup.string(),
    pointConsommable: Yup.number(),
    pointBeneficier: Yup.number(),
    subCategory: Yup.string().oneOf(
      subCategoriesParent.map((subCategoriesParent) => subCategoriesParent.id.toString()),
      'Sélectionnez une sous catégorie valide'
    ),
    category: Yup.string().oneOf(
      categories.map((category) => category.id.toString()),
      'Sélectionnez une catégorie valide'
    )

  })

  const fetchData = async () => {
     
    const response = await axios.get(`${apiUrl}/products/${id}`);

    setProducts(response.data)

    await setValues({
      title: response.data.title,
      picture: response.data.picture,        
      price: response.data.price,
      description: response.data.description,
      ingrediant: response.data.ingrediants,
      pointConsommable: response.data.pointConsommable,
      pointBeneficier: response.data.pointBeneficier,
      category: response.data.category,
      subCategory: response.data.subCategory,
      disponibility: response.data.disponibility,
      bestseller: response.data.bestseller,
      latest: response.data.latest,


    })

  
}
useEffect(() => {
  fetchData()
}, [])


  const formik = useFormik({
    initialValues: {
      title: "",
      picture: "",
      price: "",
      description: "",
      ingrediant:"",
      pointConsommable:"",
      pointBeneficier:"",
      subCategory:"",
      category:"",
      disponibility:"",
      bestseller:"",
      latest:"",

    },


    validationSchema: Schema,
    onSubmit: async (values, { resetForm }) => {
      try{
      setIsLoading(true)
      const formData = new FormData()
      const disponibility = disponibiliteState === 1 ? 'enstock' : 'rupture'
      const bestseller = bestsellerState === 1 ? 'enable' : 'disable'
      const latest = latestState === 1 ? 'enable' : 'disable'
      formData.append('title', values.title)
      formData.append('price', values.price)
      formData.append('description', values.description)
      formData.append('ingrediant', values.ingrediant)
      formData.append('pointConsommable', values.pointConsommable)
      formData.append('pointBeneficier', values.pointBeneficier)
      formData.append('subcategory', values.subCategory)
      formData.append('category', values.category)
      formData.append('disponibility', disponibility)
      formData.append('bestseller', String(bestseller))
      formData.append('latest', String(latest))
      const slug = generateSlug(values.title)
      formData.append('slug', slug)
      
      if(newImage){
        await compressAndAppendImage(newImage as File, formData,'picture',0.4)
      }

      const response = await axios.post(`${apiUrl}/product-update/${id}`, formData)
      if (response.status === 200) {
        setIsLoading(false)
        toast.success('Produit est enregistré avec succès');

      }
      else {
        toast.error('Erreur lors de l\'enregistrement de produit');
      }
    } catch (error) {
      toast.error('Erreur lors de la communication avec le serveur');
    }

    resetForm()


    },
  })


  

  

  function generateSlug(inputString: string): string {
    let string = inputString.replace(/&/g, 'et').replace(/'|’/g, '-').replace('$', '');

    const transliterated = slugify(string, {
      lower: true,
      remove: /[^a-zA-Z0-9 -]/g,
    });

    const slug = transliterated.replace(/ /g, '-').replace(/-+/g, '-').replace(/^-+|-+$/g, '');

    return slug;
  }


  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues
  } = formik

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setNewImage(e.target.files[0]);
    }
  };



  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#product'
          aria-expanded='true'
          aria-controls='product'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Modifier Un Produit</h3>
          </div>
        </div>

        <div id='product' className='collapse show'>
          <form onSubmit={handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>

              <div className='row mb-6'>
                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-lg-6 col-md-6 fv-row'>
                      <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Nom De Produit</label>

                      <input
                        type='text'
                        name='title'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Nom de categorie'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                      />
                      {formik.touched.title && formik.errors.title && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.title}</div>
                        </div>
                      )}
                    </div>

                    <div className='col-lg-6 col-md-6 fv-row'>
                      <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Ancienne image</label>

                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-70px'>
                          <img
                            src={`${productImgUrl}/${values.picture}`}
                            className='w-100 shadow'
                            alt=''
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>


                <div className='col-lg-6 col-md-6 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Nouveau Image</label>
                  <input
                    type="file"
                    name="image"
                    accept=".png, .jpg, .jpeg, .webp"
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    onChange={handleImageChange} />

                  {newImage ? (
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-70px'>
                        <img src={URL.createObjectURL(newImage)} alt='' className='w-100 shadow mt-2' />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                </div>
              </div>

              
              <div className='row'>
                  <div className='col-lg-4 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>prix</label>

                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='prix'
                      {...formik.getFieldProps('price')}
                    />
                    {formik.touched.price && formik.errors.price && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.price}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-4 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>point consommable</label>

                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='point consommable'
                      {...formik.getFieldProps('pointConsommable')}
                    />
                    {formik.touched.pointConsommable && formik.errors.pointConsommable && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.pointConsommable}</div>
                      </div>
                    )}
                  </div>


                  <div className='col-lg-4 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>point beneficier</label>

                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='point beneficier'
                      {...formik.getFieldProps('pointBeneficier')}
                    />
                    {formik.touched.pointBeneficier && formik.errors.pointBeneficier && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.pointBeneficier}</div>
                      </div>
                    )}
                  </div>

                </div>

                <div className="row mb-6">

                <div className='col-lg-3 col-md-4 fv-row'>
                  <label className='col-lg-10 col-md-10 col-form-label required fw-bold fs-6'>
                    Disponibilite
                  </label>
                  <div className='form-check form-check-custom form-check-solid form-check-info'>
                    <input
                      type='radio'
                      name='disponibiliteState'
                      id='enstock'
                      className='form-check-input form-check-disponibilite'
                      checked={disponibiliteState === 1}
                      onChange={() => setDisponibiliteState(1)}
                      onBlur={formik.handleBlur}
                      value={1}
                    />
                    <label htmlFor='enstock' className='fs-5 ms-2 me-4 col-form-label fw-bold'>
                      En Stock
                    </label>

                    <input
                      type='radio'
                      name='disponibiliteState'
                      id='rupture'
                      className='form-check-input form-check-deactive form-check-disponibilite'
                      checked={disponibiliteState === 0}
                      onChange={() => setDisponibiliteState(0)}
                      onBlur={formik.handleBlur}
                      value={0}
                    />
                    <label htmlFor='rupture' className='fs-5 ms-2 col-form-label fw-bold'>
                      Rupture
                    </label>

                  </div>
                </div>

                <div className='col-lg-3 col-md-4 fv-row'>
                  <label className='col-lg-10 col-md-10 col-form-label required fw-bold fs-6'>
                    Best Seller
                  </label>
                  <div className='form-check form-check-custom form-check-solid form-check-info'>
                    <input
                      type='radio'
                      name='bestsellerState'
                      id='enablebestseller'
                      className='form-check-input form-check-disponibilite'
                      checked={bestsellerState === 1}
                      onChange={() => setBestsellerState(1)}
                      onBlur={formik.handleBlur}
                      value={1}
                    />
                    <label htmlFor='enablebestseller' className='fs-5 ms-2 me-4 col-form-label fw-bold'>
                      Active
                    </label>

                    <input
                      type='radio'
                      name='bestsellerState'
                      id='disablebestseller'
                      className='form-check-input form-check-deactive form-check-disponibilite'
                      checked={bestsellerState === 0}
                      onChange={() => setBestsellerState(0)}
                      onBlur={formik.handleBlur}
                      value={0}
                    />
                    <label htmlFor='disablebestseller' className='fs-5 ms-2 col-form-label fw-bold'>
                      Disactive
                    </label>

                  </div>
                </div>

                <div className='col-lg-3 col-md-4 fv-row'>
                  <label className='col-lg-10 col-md-10 col-form-label required fw-bold fs-6'>
                    Nouveauté
                  </label>
                  <div className='form-check form-check-custom form-check-solid form-check-info'>
                    <input
                      type='radio'
                      name='latestState'
                      id='enablelatest'
                      className='form-check-input form-check-disponibilite'
                      checked={latestState === 1}
                      onChange={() => setLatestState(1)}
                      onBlur={formik.handleBlur}
                      value={1}
                    />
                    <label htmlFor='enablelatest' className='fs-5 ms-2 me-4 col-form-label fw-bold'>
                      Active
                    </label>

                    <input
                      type='radio'
                      name='latestState'
                      id='disablelatest'
                      className='form-check-input form-check-deactive form-check-disponibilite'
                      checked={latestState === 0}
                      onChange={() => setLatestState(0)}
                      onBlur={formik.handleBlur}
                      value={0}
                    />
                    <label htmlFor='disablelatest' className='fs-5 ms-2 col-form-label fw-bold'>
                      Disactive
                    </label>

                  </div>
                </div>
                </div>

                <div className='col-lg-12 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Description</label>

                <textarea
                  rows={5}
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Description'
                  {...formik.getFieldProps('description')}
                ></textarea>
                {formik.touched.description && formik.errors.description && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.description}</div>
                  </div>
                )}
              </div>

                <div className='col-lg-12 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>ingrediant</label>

                <textarea
                  rows={5}
                  className='form-control form-control-lg form-control-solid'
                  placeholder='ingrediant'
                  {...formik.getFieldProps('ingrediants')}
                ></textarea>
                {formik.touched.ingrediant && formik.errors.ingrediant && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.ingrediant}</div>
                  </div>
                )}
              </div>

              <div className='row mb-6'>
                      <div className='col-lg-6 col-md-6 fv-row'>
                        <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Categories</label>

                        <select
                        className="form-select"
                        value={formik.values.category}
                        onChange={(event) => {
                          formik.setFieldValue('category', event.target.value)
                        }}
                      >
                        <option value="">Sélectionnez une catégorie</option>
                        {categories.map((categorie, index) => (
                          <option key={index} value={categorie.id}>
                            {categorie.title}
                          </option>
                        ))}
                      </select>
                        {formik.touched.category && formik.errors.category && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.category}</div>
                          </div>
                        )}
                      </div>
                      </div>

                      
              <div className='row mb-6'>
              <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>sous Categories</label>

                    <select
                    className="form-select"
                    value={formik.values.subCategory}
                    onChange={(event) => {
                      formik.setFieldValue('subcategory', event.target.value)
                    }}
                  >
                    <option value="">Sélectionnez sous catégorie</option>
                    {subCategoriesParent.map((subcategorie, index) => (
                      <option key={index} value={subcategorie.id}>
                        {subcategorie.title}
                      </option>
                    ))}
                  </select>
                    {formik.touched.subCategory && formik.errors.subCategory && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.subCategory}</div>
                      </div>
                    )}
                  </div>
              </div>

            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={isloading}>
              {!isloading && 'Save Changes'}
              {isloading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          </form>
        </div>

      </div>

     

               


    </>
  )
}

export default UpdateProduct