import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { ProductProps, UrlParamsProps } from '../../../_metronic/helpers/Props'
import { apiUrl, productImgUrl } from '../../../_metronic/helpers/Helpers'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './switch.css'
import { FaFilter } from 'react-icons/fa'
import { Pagination } from '@mui/material';
import useApiRequest from '@mbs-dev/api-request'
import { frCustomeErrorNotify } from '@mbs-dev/react-helpers'
import { useCategoriesContext } from '../../context/CategoriesContext'
import { useSubCategoriesParentContext } from '../../context/SubCategoriesParentContext'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { useSubCategoryContext } from '../../context/SubCategoryContext'
import { Loading } from '../../../_metronic/helpers/Loading'
import { HiOutlineRefresh } from 'react-icons/hi'






const ListProduct: React.FC = () => {

  
  const [products, setProducts] = useState<ProductProps[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const { categories } = useCategoriesContext()
  const { subcategories } = useSubCategoryContext()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { apiRequest } = useApiRequest()
  const [isLoadingData, setIsLoadingData] = useState(false)


  // Pagination state
  const [isFilter, setIsFilter] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [filterCurrentPage, setFilterCurrentPage] = useState(1)
  const [filterTotalPages, setFilterTotalPages] = useState(1)
  const [defaultPage, setDefaultPage] = useState(1)
  

  const storedUrl = localStorage.getItem('lastProdResponseUrl')
  const storedParams = localStorage.getItem('storedProdPage')
  let urlParams: UrlParamsProps
  if (storedParams) {
    urlParams = JSON.parse(storedParams)
  }

  // Filter state
  const [selectedCategory, setSelectedCategory] = useState('')
  const [productTitle, setProductTitle] = useState('')
  const [productReference, setProductReference] = useState('')
  const [productDisponibilite, setProductDisponibilite] = useState('')
  const [productBestseller, setProductBestseller] = useState('')
  const [productMarque, setProductMarque] = useState('')
  const [productSubCategory, setProductSubCategory] = useState('')
  const [inputFilter, setInputFilter] = useState(false)
  const [openFilter, setOpenFilter] = useState(true)

  const filterConditions = productSubCategory !== '' || productTitle !== '' || productReference !== '' || productDisponibilite !== '' || productBestseller !== '' || selectedCategory !== ''

  const stored = localStorage.getItem('storedProdPage')
  useEffect(() => {
    if (stored) {
      setCurrentPage(Number(stored))
    }
  }, [])

  const handleCategoryChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setSelectedCategory(event.target.value)
  }


  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/products.jsonld`, {
        params: {
          page: currentPage,
          itemsPerPage: 10
        },
        headers: {
          Accept: 'application/ld+json'
        }
      })

      setProducts(response.data['hydra:member'])
      setTotalPages(Math.ceil(response.data['hydra:totalItems'] / 10))
      setIsLoadingData(false)
    } catch {
      frCustomeErrorNotify()
    }
  }
  useEffect(() => {
    fetchData()
  }, [])

  
  const filterData = async () => {
    setIsFilter(true)
    setIsLoading(true)

    try {
      const config: AxiosRequestConfig = {
        url: `${apiUrl}/products.jsonld`,
        params: {
          title: productTitle || null,
          disponibilite: productDisponibilite || null,
          bestseller: productBestseller || null,
          reference: productReference || null,
          subCategorie: productMarque || null,
          categorie: productSubCategory || selectedCategory || null,
          page: filterCurrentPage,
          itemsPerPage: 10
        },
        headers: {
          'Accept': 'application/ld+json',
        },
      }

      const filteredParams = Object.entries(config.params)
        .filter(([key, value]) => value !== null && value !== undefined)
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})

      const response: AxiosResponse = await axios.get(config.url as string,
        {
          params: filteredParams,
          headers: config.headers,
        },
      )

      if (response.status === 200) {
        const requestUrl = `${config.url}?${new URLSearchParams(filteredParams).toString()}`
        localStorage.setItem('lastProdResponseUrl', requestUrl)
        localStorage.setItem('prodParams', JSON.stringify(filteredParams))

        setProducts(response.data['hydra:member'])
        setFilterTotalPages(Math.ceil(response.data['hydra:totalItems'] / 10))
        setIsLoading(false)
      } else {
        frCustomeErrorNotify()
        setIsLoading(false)
      }
    } catch {
      frCustomeErrorNotify()
      setIsLoading(false)
    }
  }


  const DeleteProducts = async (productId: number) => {
    try {
      const response = await axios.delete(`${apiUrl}/products/${productId}`)

      if (response.status === 204) {
        setIsLoading(false)
        toast.success('Produit est supprimer avec succès');

        // fetchData()
      }
      else {
        toast.error('Erreur lors de la supprission de produit');
      }
    } catch {
      toast.error('Erreur lors de la communication avec le serveur');

    }

  }

  const changeDisponibilite = async (productId: number, disponibility: string) => {
    try {

      const formData = new FormData()
      formData.append('id', String(productId))
      formData.append('disponibility', disponibility)

      const response = await axios.post(`${apiUrl}/product-update/${productId}`,formData); 

      if (response.status === 200) {
        if (disponibility === 'enstock') {
          toast.success("Le produit a été mis en enstock ")
          fetchData()
        }
        if (disponibility === 'rupture') {
          toast.error("Le produit a été mis en rupture")
          fetchData()

        }
      }

    } catch {
      toast.error('Erreur lors de la communication avec le serveur');

    }
  }
 
  function handlePageChange(event: any, value: any) {
    setCurrentPage(value)
    localStorage.setItem('storedProdPage', String(value))
  }

  function handleFilterPageChange(event: any, value: any) {
    setFilterCurrentPage(value)
    localStorage.setItem('storedProdPage', String(value))
  }
  
  useEffect(() => {
    if (!storedUrl) {
      fetchData()
    }

  }, [currentPage])

  useEffect(() => {
    if (isFilter) {
      filterData()
    }
  }, [filterCurrentPage])

  useEffect(() => {
    if (filterConditions) {
      setInputFilter(true)
    }
  }, [productMarque, productTitle, productReference, productDisponibilite, productBestseller, selectedCategory])
  
  const filterStoredData = async (url: string) => {
    setIsFilter(true)
    setIsLoading(true)
    if (urlParams.title) {
      setProductTitle(urlParams.title)
    }
    if (urlParams.reference) {
      setProductReference(urlParams.reference)
    }
    if (urlParams.category) {
      setProductMarque(urlParams.category)
    }
    if (Number(urlParams.subCategory) > 10) {
      setProductSubCategory(urlParams.subCategory)
    } else {
      setSelectedCategory(urlParams.subCategory)
    }
    if (urlParams.disponibility) {
      setProductDisponibilite(urlParams.disponibility)
    }
    if (urlParams.bestseller) {
      setProductBestseller(urlParams.bestseller)
    }
    if (urlParams.page) {
      setFilterCurrentPage(urlParams.page)
    }

    try {
      const response = await axios.get(url, {
        headers: {
          Accept: 'application/ld+json'
        }
      })

      if (response.status === 200) {
        setIsLoading(false)
        setProducts(response.data['hydra:member'])
        setFilterTotalPages(Math.ceil(response.data['hydra:totalItems'] / 10))
      } else {
        frCustomeErrorNotify()
        setIsLoading(false)
      }
    } catch {
      frCustomeErrorNotify()
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (storedUrl) {
      filterStoredData(storedUrl)
    }
  }, [])

  const resetFilter = () => {
    setProductTitle('')
    setProductReference('')
    setProductDisponibilite('')
    setProductBestseller('')
    setProductMarque('')
    setSelectedCategory('')
    setProductSubCategory('')
    setIsFilter(false)
    setInputFilter(false)
    setCurrentPage(1)
    setFilterCurrentPage(1)
    localStorage.removeItem('storedProdPage')
    localStorage.removeItem('lastProdResponseUrl')
    localStorage.removeItem('prodParams')
    setDefaultPage(1)
    fetchData()
  }

  const checkAndFetch = () => {
    if (filterConditions) {
      filterData()
    } else {
      fetchData()
    }
  }

  const handleDropdownOpen = (index: number) => {
    setIsDropdownOpen(!isDropdownOpen)
    setSelectedIndex(index)
  }
 
  return (

    
    <div className={`card`}>
  <div className={`card mb-4 py-5 ${openFilter ? 'filter-card-0' : 'filter-card'}`}>
<div className='card-body py-3'>
  <div className="container">
    <div className={`row justify-content-start align-items-center ${openFilter ? '' : 'd-none'}`}>
      <div className="col-md-6 col-lg-6 col-xl-3 col-12">
        <label className='col-lg-8 col-md-8 col-form-label fw-bold fs-6'>
          Nom de Produit
        </label>
        <input
          className="form-control "
          type="text"
          aria-label="Search"
          placeholder='Nom de Produit'
          value={productTitle}
          onChange={(e) => setProductTitle(e.target.value)} />
      </div>

      <div className="col-md-6 col-lg-6 col-xl-2 col-12">
        <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Categorie</label>
        <select
          name="categorie"
          className="form-select"
          onChange={handleCategoryChange}
          value={selectedCategory}
        >
          <option value="" disabled>Categorie</option>
          {categories.map((categorie: any, index) => {
            return (
              <option key={index} value={categorie.id}>
                {categorie.title}
              </option>
            )
          })}
        </select>
      </div>

      <div className="col-md-6 col-lg-6 col-xl-2 col-12">
        <label className='col-lg-10 col-md-10 col-form-label fw-bold fs-6'>Sous Categorie</label>
        <select
          className="form-select"
          onChange={(e) => setProductSubCategory(e.target.value)}
          value={productSubCategory}
        >
          <option value="">Sous Categorie</option>
          {subcategories.map((subCategory) => (
            <option key={subCategory.id} value={subCategory.id}>
              {subCategory.title}
            </option>
          ))}
        </select>
      </div>

      {/* Start Disponibilite */}
      <div className="row">
        <div className="col-md-6 col-lg-4 col-xl-4 col-12">
          <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>
            Disponibilite
          </label>
          <div className='form-check form-check-custom form-check-solid form-check-info'>

            <input
              type='checkbox'
              name='disponibilite'
              id='all'
              className='form-check-input scale-1'
              checked={productDisponibilite === ''}
              onChange={() => setProductDisponibilite('')}
              value={''}
            />
            <label htmlFor='' className='ms-3 col-form-label fw-bold fs-5'>
              Toutes
            </label>

            <input
              type='checkbox'
              name='disponibilite'
              id='enstock'
              className='form-check-input ms-4 scale-1'
              checked={productDisponibilite === 'enstock'}
              onChange={() => setProductDisponibilite('enstock')}
              value={'enstock'}
            />
            <label htmlFor='enstock' className='ms-3 col-form-label fw-bold fs-5'>
              En Stock
            </label>


            <input
              type='checkbox'
              name='disponibilite'
              id='rupture'
              className='form-check-input ms-4 scale-1'
              checked={productDisponibilite === 'rupture'}
              onChange={() => setProductDisponibilite('rupture')}
              value={'rupture'}
            />
            <label htmlFor='rupture' className='ms-3 col-form-label fw-bold fs-5'>
              Rupture
            </label>

          </div>
        </div>

        <div className="col-md-6 col-lg-4 col-xl-4 col-12">
          <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>
            Best Seller
          </label>
          <div className='form-check form-check-custom form-check-solid form-check-info'>

            <input
              type='checkbox'
              name='bestseller'
              id='all'
              className='form-check-input scale-1'
              checked={productBestseller === ''}
              onChange={() => setProductBestseller('')}
              value={''}
            />
            <label htmlFor='all' className='ms-3 col-form-label fw-bold fs-5'>
              Toutes
            </label>

            <input
              type='checkbox'
              name='bestseller'
              id='enablebestseller'
              className='form-check-input ms-4 scale-1'
              checked={productBestseller === 'enable'}
              onChange={() => setProductBestseller('enable')}
              value={'enable'}
            />
            <label htmlFor='enablebestseller' className='ms-3 col-form-label fw-bold fs-5'>
              Oui
            </label>

            <input
              type='checkbox'
              name='bestseller'
              id='disablebestseller'
              className='form-check-input ms-4 scale-1'
              checked={productBestseller === 'disable'}
              onChange={() => setProductBestseller('disable')}
              value={'disable'}
            />
            <label htmlFor='disablebestseller' className='ms-3 col-form-label fw-bold fs-5'>
              Non
            </label>

          </div>
        </div>
      </div>
      {/* End bestseller */}



    </div>

    <div className="row">
      <div className={`${openFilter ? '' : 'd-none'}`}>
        <div className="ico" onClick={resetFilter}>
          <HiOutlineRefresh className='filter-icon' />
        </div>
      </div>

      <div className={``}>
        <div className={`open-filter-ico d-flex-center ${openFilter ? 'open-filter-radius right-0' : 'left-0'}`} onClick={() => setOpenFilter(!openFilter)}>
          <FaFilter className='open-filter-icon' />
        </div>
      </div>
    </div>

    <div className={`row justify-content-start align-items-center ${openFilter ? '' : 'd-none'}`}>
      <div className="col-md-6 col-lg-6 col-xl-2 col-12">
        {
          !isLoading ?
            <>
              <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6 '>
              </label>
              <button className='btn btn-light-primary me-3 fw-bold mt-4' onClick={filterData} disabled={!inputFilter} >
                <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                Filtrer
              </button>

            </>

            :
            <div className="me-3 mt-4 d-flex justify-content-start">
              <Loading />
            </div>
        }
      </div>
    </div>
  </div>
</div>

</div>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Liste Des produits</span>
        </h3>
        <div className='card-toolbar'>
          <Link to='/admin/AddProduct' className='btn btn-sm btn-light-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Nouveau Produit
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */} 
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-125px rounded-start'>Picture</th>
                <th className='ps-4 min-w-150px'>Nom de produit</th>
                <th className='ps-4 min-w-100px'>Prix</th>
                <th className='ps-4 min-w-150px'>Points Consommable</th>
                <th className='ps-4 min-w-150px'>Points Beneficier</th>
                <th className='ps-4 min-w-125px'>Disponibilité</th>
                <th className='min-w-150px text-end rounded-end pe-5'>Action</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {products.map((item, index) => {
                      return (
                        <tr key={item.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-50px'>
                          <img
                            src={`${productImgUrl}/${item.picture}`}
                            className='w-100'
                            alt=''
                          />
                        </div>
                      </div>
                    </td>

                    <td>
                      <span className='text-dark fw-bold d-block ps-3 mb-1 fs-6'>
                        {item.title}
                      </span>
                    </td>

                    <td>
                      <span className='text-dark fw-bold d-block ps-2 mb-1 fs-6'>
                        {item.price}
                      </span>
                    </td>

                    <td>
                      <span className='text-dark fw-bold d-block mb-1 ps-17 fs-6'>
                        {item.pointConsommable}
                      </span>
                    </td>

                    <td>
                      <span className='text-dark fw-bold d-block ps-17 mb-1 fs-6'>
                        {item.pointBeneficier}
                      </span>
                    </td>
                   

                   

                          <td className=''>
                            {item.disponibility === 'enstock' ?
                              <div className='form-check form-switch form-switch-sm form-check-custom form-check-success '>
                                <input
                                  className='form-check-input form-check-input-true'
                                  type='checkbox'
                                  value='rupture'
                                  name='disponibilite'
                                  onChange={() => changeDisponibilite(item.id, 'rupture')}
                                  checked={item.disponibility === 'enstock' ? true : false}
                                />
                                <label className='form-check-label fw-bold'>En Stock</label>
                              </div>
                              :
                              <div className='form-check form-switch form-switch-sm form-check-custom form-check-danger'>
                                <input
                                  className='form-check-input form-check-input-false'
                                  type='checkbox'
                                  value='enstock'
                                  name='disponibilite'
                                  onChange={() => changeDisponibilite(item.id, 'enstock')}
                                  checked={item.disponibility === 'enstock'}
                                />
                                <label className='form-check-label fw-bold'>Rupture</label>
                              </div>
                            }
                          </td>
                    
                    <td className='text-end'>
                      <Link to={`/admin/Update-Product/${item.id}`} className='btn btn-icon btn-light-primary btn-sm me-3'>
                        <i className='bi bi-pen-fill fs-4'></i>
                      </Link>
                      <button onClick={() => DeleteProducts(item.id)} className='btn btn-icon btn-light-danger btn-sm'>
                        <i className='bi bi-trash-fill fs-4'></i>
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      {
        isFilter
          ? <Pagination
            count={filterTotalPages}
            page={filterCurrentPage}
            color='primary'
            onChange={handleFilterPageChange}
            className='d-flex justify-content-center'
          />
          :
          <Pagination
            count={totalPages}
            page={currentPage}
            color='primary'
            onChange={handlePageChange}
            className='d-flex justify-content-center'
          />
      }
      <div className={`card mt-2 d-none`}>

<div className='card-body p-1'>
  <div className="container">
    <div className="row justify-content-center align-items-center">

      <div className="col-4 money">
        <input
          type="text"
          placeholder='Numéro de page'
          min={1}
          defaultValue={defaultPage}
          className='form-control form-control-lg form-control-dark'
          onChange={(e) => {
            const inputText = e.target.value
            const numbersOnly = inputText.replace(/\D/g, '')
            if (Number(numbersOnly) !== 0 && numbersOnly.length > 0 && Number(numbersOnly) <= Number(totalPages)) {
              setCurrentPage(Number(numbersOnly))
              if (isFilter) {
                setFilterCurrentPage(Number(numbersOnly))
              }
              setDefaultPage(Number(numbersOnly))
              localStorage.setItem('storedProdPage', numbersOnly)
            }
            e.target.value = numbersOnly
          }} />
      </div>

    </div>
  </div>
</div>

</div>
    </div>
  )
}

export default ListProduct