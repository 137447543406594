import removeTransparentBackground from 'react-remove-transparent-bg';
import { imageCompressor } from '@mbs-dev/react-image-compressor';
import { LabelProps } from './Props';


export const FormLabel = ({ value, isRequired = true, className }: LabelProps) => {
  return <label className={`col-lg-6 col-md-6 col-form-label fw-bold fs-6 ${isRequired ? 'required' : ''} ${className}`}>{value}</label>
}
export const processAndAppendImage = async (file: File, formData: FormData, fieldName: any | string, quality: number = 0.4) => {
    const imageWithoutTransparentBg = await removeTransparentBackground(file as File);
    const compressedImage = await imageCompressor(imageWithoutTransparentBg, quality);
    const filename = file.name.replace(' ', '-').slice(0, file.name.lastIndexOf('.')) + '.webp';
  
    formData.append(fieldName, compressedImage as File, filename);
  };
  

export const apiUrl = process.env.REACT_APP_API_URL
export const uploadUrl = process.env.REACT_APP_API_UPLOAD
export const categorieImgUrl = `${uploadUrl}/category_images`
export const subcategorieImgUrl = `${uploadUrl}/subcategory_images`
export const productImgUrl = `${uploadUrl}/product_images`
export const varianteImgUrl = `${uploadUrl}/variante_images`
export const userImgUrl = `${uploadUrl}/user_images`
export const zoneImgUrl = `${uploadUrl}/zone_images`
export const tableImgUrl = `${uploadUrl}/table_images`
export const sliderImgUrl = `${uploadUrl}/slider_images`
export const packImgUrl = `${uploadUrl}/pack_images`

