import React, { createContext, useContext, useState, useEffect } from 'react';
import { CategoryProps } from '../../_metronic/helpers/Props';
// import { useApiRequest } from '../../_metronic/helpers/ApiRequest';
import axios from 'axios';
import { apiUrl } from '../../_metronic/helpers/Helpers';


const CategoriesContext = createContext<{
    categories: CategoryProps[];
}>({
    categories: [],
});

export const useCategoriesContext = () => useContext(CategoriesContext);

export const CategoriesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [categories, setCategories] = useState<CategoryProps[]>([]);
    // const apiRequest = useApiRequest()

    const fetchData = async () => {
        try {
          const response = await axios.get(`${apiUrl}/categories`)
    
          setCategories(response.data)
          
        } catch {
        }
      }
      useEffect(() => {
        fetchData()
      }, [])

    return (
        <>
            <CategoriesContext.Provider value={{ categories }}>
                {children}
            </CategoriesContext.Provider>
        </>

    );
};
