import React, { useState, ChangeEvent } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import axios from 'axios'
import { apiUrl } from '../../../_metronic/helpers/Helpers'
import { compressAndAppendImage} from '@mbs-dev/react-image-compressor'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import slugify from 'slugify';
import { useZoneContext } from '../../context/ZoneContext'



const UpdateTable: React.FC = () => {

  const [loading, setIsLoading] = useState(false)
  const [imageFile, setimageFile] = useState<File>()
  const { zone } = useZoneContext()


  const Schema = Yup.object().shape({
    nbr_table: Yup.string().required('Le numero de table est obligatoire'),
    nbrPersonne: Yup.number().required('Le numero de personne est obligatoire'),
    zone: Yup.string().oneOf(
      zone.map((zone) => zone.id.toString()),
      'Sélectionnez une Zone valide'
    )

  })

  const formik = useFormik({
    initialValues: {
      nbr_table: "",
      zone: "",
      nbrPersonne: "",
      slug: ""

    },


    validationSchema: Schema,
    onSubmit: async (values) => {
      // setIsLoading(true)
     
      const formData = new FormData()
      formData.append('nbr_table', values.nbr_table)
      formData.append('nbrPersonne', values.nbrPersonne)
      formData.append('zone', values.zone)
      const slug = generateSlug(values.nbr_table)
      formData.append('slug', slug)

      try {
        const response = await axios.post(`${apiUrl}/addtable`, formData);
    
        if (response.status === 200) {
          setIsLoading(false)
          toast.success('Table est enregistré avec succès');
        } 
        else {
          toast.error('Erreur lors de l\'enregistrement de table');
        }
      } catch (error) {
        toast.error('Erreur lors de la communication avec le serveur');
      }




    },
  })


  function generateSlug(inputString: string): string {
    let string = inputString.replace(/&/g, 'et').replace(/'|’/g, '-').replace('$', '');

    const transliterated = slugify(string, {
      lower: true,
      remove: /[^a-zA-Z0-9 -]/g,
    });

    const slug = transliterated.replace(/ /g, '-').replace(/-+/g, '-').replace(/^-+|-+$/g, '');

    return slug;
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#category'
        aria-expanded='true'
        aria-controls='category'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Ajouter Une Table</h3>
        </div>
      </div>

      <div id='zone' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>

          <div className='card-body border-top p-9'>

            <div className='row mb-6'>
              <div className='col-lg-12'>
                <div className='row'>
                  <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Nom de Table</label>

                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='numero de table'
                      {...formik.getFieldProps('nbr_table')}
                    />
                    {formik.touched.nbr_table && formik.errors.nbr_table && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.nbr_table}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Nombre de personne</label>

                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='numero de personne'
                      {...formik.getFieldProps('nbrPersonne')}
                    />
                    {formik.touched.nbrPersonne && formik.errors.nbrPersonne && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.nbrPersonne}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Zone</label>

                  <select
                    className="form-select"
                    value={formik.values.zone}
                    onChange={(event) => {
                      formik.setFieldValue('zone', event.target.value)
                    }}
                  >
                    <option value="">Sélectionnez une catégorie</option>
                    {zone.map((zone, index) => (
                      <option key={index} value={zone.id}>
                        {zone.title}
                      </option>
                    ))}
                  </select>
                    {formik.touched.zone && formik.errors.zone && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.zone}</div>
                      </div>
                    )}
                  </div>

                </div>
              </div>
            </div>

          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>

        </form>
      </div>
    </div>
  )
}

export default UpdateTable