import React, { useState, useEffect, ChangeEvent } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { apiUrl, categorieImgUrl, productImgUrl, userImgUrl, zoneImgUrl } from '../../../_metronic/helpers/Helpers'
import { UserProps } from '../../../_metronic/helpers/Props'
import { compressAndAppendImage} from '@mbs-dev/react-image-compressor'
import slugify from 'slugify';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Schema = Yup.object().shape({
  firstname: Yup.string(),
  lastname: Yup.string(),
  email: Yup.string(),
  phoneNumber: Yup.number(),
  birthday: Yup.string(),
  password: Yup.string(),
})

const UpdateZone: React.FC = () => {

  const [isloading, setIsLoading] = useState(false)
  const [isSubloading, setIsSubLoading] = useState(false)

  const { id } = useParams()
  const [newImage, setNewImage] = useState<File | null>(null)
  const [user, setUser] = useState<UserProps>()
  const [SubExist, setSubExist] = useState(false)
  const [parentTitle, setParentTitle] = useState()
  const [imagefile, setImage] = useState<File | any>(null)

  const [file, setFile] = useState<string>("")

  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/zones/${id}`);

      setUser(response.data)
      await setValues({
        title: response.data.title,
        picture: response.data.picture,
        description: response.data.description,
       
        
        
      })    
    } catch {
    }
  }
  useEffect(() => {
    fetchData()
  }, [])


  const formik = useFormik({
    initialValues: {
      title: "",
      picture: "",
      description: "",

    },
    validationSchema: Schema,
    onSubmit: async (values, { resetForm }) => {

      try {
        setIsLoading(true)
        const formData = new FormData()
        formData.append('title', values.title)
        formData.append('picture', values.picture)
        formData.append('description', values.description)      
        if (newImage) {
          await compressAndAppendImage(newImage as unknown as File, formData, 'picture',0.4)
        }


        const response = await axios.post(`${apiUrl}/update-zone/${id}`, formData);
          console.log(formData);
        
        if (response.status === 200) {
          setIsLoading(false)
          toast.success('Zone est modifier avec succès'); 
        }else {
          toast.error('Erreur lors de la modification de Compte');
        }

      } catch {
        toast.error('Erreur lors de la communication avec le serveur');

      }

      resetForm()
    },
  })

  

  

  function generateSlug(inputString: string): string {
    let string = inputString.replace(/&/g, 'et').replace(/'|’/g, '-').replace('$', '');

    const transliterated = slugify(string, {
      lower: true,
      remove: /[^a-zA-Z0-9 -]/g,
    });

    const slug = transliterated.replace(/ /g, '-').replace(/-+/g, '-').replace(/^-+|-+$/g, '');

    return slug;
  }


  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues
  } = formik

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setNewImage(e.target.files[0]);
    }
  };



  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#product'
          aria-expanded='true'
          aria-controls='product'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Modifier Une Zone</h3>
          </div>
        </div>

        <div id='product' className='collapse show'>
          <form onSubmit={handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>

              <div className='row mb-6'>
                <div className='col-lg-12'>
                  <div className='row'>

                  <div className='col-lg-6 col-md-6 fv-row'>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-70px'>
                          <img
                            src={`${zoneImgUrl}/${values.picture}`}
                            className='w-100 shadow'
                            alt=''
                          />
                        </div>
                      </div>
                    </div>

                    <div className='col-lg-6 col-md-6 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Nouveau Image</label>
                  <input
                    type="file"
                    name="picture"
                    accept=".png, .jpg, .jpeg, .webp"
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    onChange={handleImageChange} />

                  {newImage ? (
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-70px'>
                        <img src={URL.createObjectURL(newImage)} alt='' className='w-100 shadow mt-2' />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                </div>

                    <div className='col-lg-6 col-md-6 fv-row'>
                      <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Title</label>

                      <input
                        type='text'
                        name='title'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Nom de categorie'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                      />
                      {formik.touched.title && formik.errors.title && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.title}</div>
                        </div>
                      )}
                    </div>

                    <div className='col-lg-6 col-md-6 fv-row'>
                      <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Description</label>

                      <input
                        type='text'
                        name='description'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Nom de categorie'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                      />
                      {formik.touched.description && formik.errors.description && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.description}</div>
                        </div>
                      )}
                    </div>
                    
                  </div>
                </div>
              </div>

            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={isloading}>
              {!isloading && 'Save Changes'}
              {isloading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          </form>
        </div>

      </div>






    </>
  )
}

export default UpdateZone