import React, { createContext, useContext, useState, useEffect } from 'react';
import {SubCategoryProps } from '../../_metronic/helpers/Props';
// import { useApiRequest } from '../../_metronic/helpers/ApiRequest';
import axios from 'axios';
import { apiUrl } from '../../_metronic/helpers/Helpers';


const SubCategoryContext = createContext<{
    subcategories: SubCategoryProps[];
}>({
    subcategories: [],
});

export const useSubCategoryContext = () => useContext(SubCategoryContext);

export const SubCategoryProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [subcategories, setSubCategories] = useState<SubCategoryProps[]>([]);

    const fetchData = async () => {
        try {
          const response = await axios.get(`${apiUrl}/listsubcategory`)
          setSubCategories(response.data)          
        } catch {
        }
      }
      useEffect(() => {
        fetchData()
      }, [])

    return (
        <>
            <SubCategoryContext.Provider value={{ subcategories }}>
                {children}
            </SubCategoryContext.Provider>
        </>

    );
};
