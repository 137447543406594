import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { OrderProps, ReservationProps, VarianteProps } from '../../../_metronic/helpers/Props'
import { apiUrl, varianteImgUrl } from '../../../_metronic/helpers/Helpers'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { HiOutlineRefresh } from 'react-icons/hi'
// import OrderAdressesModal from './OrderAdressesModal'
// import OrderDetailsModal from './OrderDetailsModal'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
import html2pdf from 'html2pdf.js'
import { Loading } from '../../../_metronic/helpers/Loading'
// import OrderFacture from './OrderFacture'
import { formatDate, frCustomeErrorNotify, successNotify } from '@mbs-dev/react-helpers'
import useApiRequest from '@mbs-dev/api-request'
import { Pagination } from '@mui/material'
import ReservationDetailsModal from '../Reservation/ReservationDetailsModal'


const ListReservationOffline: React.FC = () => {

  const [isloading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [fullName, setFullName] = useState('')
  const [reference, setReference] = useState('')
  const [status, setStatus] = useState<null | string>(null)
  const [isFilterLoading, setIsFilterLoading] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState<any | null>(null)
  const [showDetailsModal, setShowDetailsModal] = useState(false)
  const [reservation, setReservation] = useState<ReservationProps[]>([])
  const [reservationDate, setReservationsDate] = useState('')
  const [isPrintLoading, setIsPrintLoading] = useState(false)
  const { id } = useParams()
  const { apiRequest } = useApiRequest()


  // Pagination state
  const [isFilter, setIsFilter] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [filterCurrentPage, setFilterCurrentPage] = useState(1)
  const [filterTotalPages, setFilterTotalPages] = useState(1)
  const [inputFilter, setInputFilter] = useState(false)
  const [filterTotalItems, setFilterTotalItems] = useState(0)


  const initialReservationState = reservation.map(reservation => ({
    ...reservation,
    isLoadingValidate: false,
    isLoadingAnnuler: false,
    isLoadingShipping: false,
    isPrinting: false,
  }))
  const [reservationsWithLoading, setReservationsWithLoading] = useState(initialReservationState)

  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/reservations.jsonld`, {
        params: {
          page: currentPage,
          itemsPerPage: 10
        },
        headers: {
          Accept: 'application/ld+json'
        }
      })


      const updatedOrders = response.data['hydra:member'].map((order: any) => ({ ...order, isLoading: false }))

      // setOrders(response.data['hydra:member'])
      setReservationsWithLoading(updatedOrders)
      setTotalItems(response.data['hydra:totalItems'])
      setTotalPages(Math.ceil(response.data['hydra:totalItems'] / 10))
    } catch {
      frCustomeErrorNotify()
    }
  }

  useEffect(() => {
    fetchData()
  }, [currentPage, totalItems])


  const DeleteReservation = async (id: number) => {
    try {
      const response = await axios.delete(`${apiUrl}/reservations/${id}`)

      if (response.status === 204) {
        setIsLoading(false)
        toast.success('Produit est supprimer avec succès');

        // fetchData()
      }
      else {
        toast.error('Erreur lors de la supprission de produit');
      }
    } catch {
      toast.error('Erreur lors de la communication avec le serveur');

    }

  }


  const changeReservationStatus = async (id: number, status: string) => {
    try {
      const data = new FormData()
      data.append('orderStatue', status)

      const response = await apiRequest({
        route: `${apiUrl}/update-status/${id}`,
        method: 'POST',
        // headers: {
        //   "Content-Type": "application/json"
        // },
        data: data

      });

      if (response.status === 200) {
        fetchData();
        if (status === 'validate') {
          successNotify("Commande bien validé");
        } else if (status === 'shipping') {
          successNotify("Commande en cours d'expédition");
        } else if (status === 'annuler') {
          successNotify("Commande bien annulée");
        }
      }
    } catch {
      frCustomeErrorNotify();
    }
  }


  function handlePageChange(event: any, value: any) {
    setCurrentPage(value)
  }

  function handleFilterPageChange(event: any, value: any) {
    setFilterCurrentPage(value)
  }
  const showLocation = (index: number) => {
    setSelectedOrder(reservationsWithLoading[index])
    setShowModal(true)
  }

  const showDetails = (index: number) => {
    setSelectedOrder(reservationsWithLoading[index])
    setShowDetailsModal(true)
  }

  const hideDetailsModal = () => {
    setShowDetailsModal(false)
  }

  const hideLocationModal = () => {
    setShowModal(false)
  }

  const filterData = async () => {
    setIsFilter(true)
    setIsFilterLoading(true)

    try {

      const params: Record<string, any> = {
        'user.firstname': fullName || '',
        page: filterCurrentPage,
        itemsPerPage: 10
      }

      if (status !== '') {
        params.$orderStatue = status
      }
      if (reservationDate !== '') {
        params.dateCreate = reservationDate
      }
      if (reference !== '') {
        params.reference = reference
      }

      const response = await axios.get(`${apiUrl}/reservations.jsonld`, {
        params: params,
        headers: {
          Accept: 'application/ld+json'
        }
      })

      if (response.status === 200) {
        setIsFilterLoading(false)
        setReservationsWithLoading(response.data['hydra:member'])
        setFilterTotalItems(response.data['hydra:totalItems'])
        setFilterTotalPages(Math.ceil(response.data['hydra:totalItems'] / 10))
      } else {
        setIsFilterLoading(false)
      }
    } catch {
      setIsFilterLoading(false)
    }
  }
  useEffect(() => {
    if (isFilter) {
      filterData()
    }
  }, [filterCurrentPage])

  useEffect(() => {
    if (status !== null || fullName !== '' || reference !== '' || reservationDate !== '') {
      setInputFilter(true)
    }
  }, [status, fullName, reference, reservationDate])


  const resetFilter = () => {
    setStatus(null)
    setReservationsDate('')
    setFullName('')
    setReference('')
    setIsFilter(false)
    setInputFilter(false)
    setCurrentPage(1)
    setFilterCurrentPage(1)
    fetchData()
    // setIsLoading(false)
    // setOrdersWithLoading(null)
  }

  function generatePDF(reference: string, index: number) {
    setIsPrintLoading(true)

    const updatedOrders = [...reservationsWithLoading]
    updatedOrders[index].isPrinting = true
    setReservationsWithLoading(updatedOrders)


    const element = document.getElementById(`canvas_div_pdf${reference}`);

    if (element) {
      html2pdf(element, {
        margin: 5,
        filename: `C-${reference}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: {
          scale: 2,
          logging: true,
          dpi: 192,
          letterRendering: true,
        },
        jsPDF: { unit: 'mm', orientation: 'portrait' },
        // jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        // jsPDF: { unit: 'mm', format: 'legal', orientation: 'portrait' },
      }).then(function (pdf: { save: () => void }) {
        setIsPrintLoading(false)

        const updatedOrders = [...reservationsWithLoading]
        updatedOrders[index].isPrinting = false
        setReservationsWithLoading(updatedOrders)

        pdf.save()
      })
    }
  }


  return (
    <>
      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>


          <div className='card-body py-3'>
            <div className="container">
              <div className="row justify-content-start align-items-center">

                {/* Start Client Name */}
                <div className="col-md-6 col-lg-6 col-xl-3 col-12">
                  <label className='col-lg-8 col-md-8 col-form-label fw-bold fs-6'>
                    N° de commande
                  </label>
                  <input
                    className="form-control "
                    type="search" aria-label="Search"
                    placeholder='N° de commande'
                    value={reference}
                    onChange={(e) => {
                      setReference(e.target.value)
                    }} />

                </div>
                {/* End Client Name */}

                {/* Start Order Date */}
                <div className="col-md-6 col-lg-6 col-xl-3 col-12">
                  <label className='col-lg-8 col-md-8 col-form-label fw-bold fs-6'>
                    Date de commande
                  </label>
                  <input
                    type='date'
                    name='dateDebut'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Date commande'
                    onChange={(e) => setReservationsDate(e.target.value)}
                    value={reservationDate}
                  />
                </div>
                {/* End Order Date */}

                {/* Start Status */}
                <div className="col-md-6 col-lg-6 col-xl-6 col-12">
                  <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>
                    Statut de commande
                  </label>
                  <div className='form-check form-check-custom form-check-solid form-check-info'>

                    {/* Validate */}
                    <input
                      type='checkbox'
                      name='status'
                      id='validate'
                      className='form-check-input scale-1'
                      checked={status === 'validate'}
                      onChange={() => setStatus('validate')}
                      value={'validate'}
                    />
                    <label htmlFor='validate' className='ms-3 col-form-label fw-bold fs-5'>
                      Validé
                    </label>

                    {/* Pending */}
                    <input
                      type='checkbox'
                      name='status'
                      id='pending'
                      className='form-check-input ms-4 scale-1'
                      checked={status === 'pending'}
                      onChange={() => setStatus('pending')}
                      value={'pending'}
                    />
                    <label htmlFor='pending' className='ms-3 col-form-label fw-bold fs-5'>
                      En cours
                    </label>


                    {/* shipping */}
                    <input
                      type='checkbox'
                      name='status'
                      id='Livrer'
                      className='form-check-input ms-4 scale-1'
                      checked={status === 'Livrer'}
                      onChange={() => setStatus('livrer')}
                      value={'Livrer'}
                    />
                    <label htmlFor='Livrer' className='ms-3 col-form-label fw-bold fs-5'>
                      livré
                    </label>


                    {/* Annuler */}
                    <input
                      type='checkbox'
                      name='status'
                      id='annuler'
                      className='form-check-input ms-4 scale-1'
                      checked={status === 'annuler'}
                      onChange={() => setStatus('annuler')}
                      value={'annuler'}
                    />
                    <label htmlFor='annuler' className='ms-3 col-form-label fw-bold fs-5'>
                      Annuler
                    </label>

                  </div>
                </div>
                {/* End Status */}

                {/* Start Apply Filter */}
                <div className="row">
                  <div className="col-md-6 col-lg-6 col-xl-2 col-12">
                    {
                      !isFilterLoading ?
                        <>
                          <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>
                          </label>
                          <button className='btn btn-light-primary me-3 fw-bold' onClick={filterData} disabled={!inputFilter} >
                            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                            Filtrer
                          </button>
                        </>

                        :
                        <div className="me-3 mt-4 d-flex justify-content-start">
                          <Loading />
                        </div>
                    }
                  </div>
                </div>
                {/* End Apply Filter */}

                {/* Start Reset Filter */}
                <div className="reset-filter ">
                  <div className="ico" onClick={resetFilter}>
                    <HiOutlineRefresh className='filter-icon sshadow' />
                  </div>
                </div>
                {/* End Reset Filter */}

              </div>
            </div>
          </div>

          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Liste Des commandes</span>
          </h3>

        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-100px rounded-start'>N° Reservation</th>
                  <th className='min-w-50px'>N°Personne</th>
                  <th className='min-w-100px'>Date Reservation</th>
                  <th className='min-w-100px'>l'heur de reservation</th>
                  <th className='min-w-100px'>Zone/Table</th>
                  <th className='min-w-100px'>Telephone</th>
                  <th className='min-w-100px'>Status</th>
                  <th className='min-w-100px text-center rounded-end pe-4'>Action</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>

                {reservationsWithLoading.map((item, index) => {
                  console.log(item)
                  return (
                    <tr key={item.id}>

                      <td className='ps-5'>
                        <span className='badge badge-light-info  fs-6'>
                          {item.reference}
                        </span>
                      </td>

                      <td className='ps-5'>
                        <span className='text-dark fw-bold d-block fs-6'>
                          {item.nbrPersonne}
                        </span>
                      </td>

                      <td className='ps-1'>
                        <span className='text-dark fw-bold d-block fs-6'>
                          {formatDate(item.dateReservation)}
                        </span>
                      </td>

                      <td>
                        <span className='text-dark fw-bold  d-block mb-1 fs-6'>
                          {item.timeOn}
                        </span>
                      </td>

                      {/* <td className='ps-1'>
                        <span className='text-dark fw-bold d-block fs-6'>
                        {`${item.zone ? item.zone?.title : null} - `}

                        {`${item.zone ? item.zone?.title : item.tables ? item.tables.nbrTable :''}`}
                        </span>
                      </td> */}
                    
                      <td>
                        <span className='text-dark fw-bold d-block mb-1 ps-9 fs-6'>
                          {item.telephone}
                        </span>
                      </td>

                      <td>
                        <span className='text-dark fw-bold  d-block mb-1 fs-6'>

                          {item.status === 'pending' && (
                            <span className='badge badge-light-primary fs-6 fw-bold px-4'>
                              En cours
                            </span>
                          )}
                          {/* {item.status === 'livrer' && (
                            <span className='badge badge-light-info fs-6 fw-bold px-4'>
                              livré
                            </span>
                          )} */}
                          {item.status === 'annuler' && (
                            <span className='badge badge-light-warning fs-6 fw-bold px-4'>
                              Annuler
                            </span>
                          )}

                          {item.status === 'validate' && (
                            <span className='badge badge-light-success fs-6 fw-bold px-4'>
                              Validé
                            </span>
                          )}

                        </span>
                      </td>

                      <td className='text-end d-non'>

                        {!false &&
                          <>
                            {
                              !item.isLoadingShipping && !item.isLoadingValidate && !item.isLoadingAnnuler ?
                                <div className="dropdown d-inline me-3">
                                  <button
                                    className="btn btn-secondary dropdown-toggle px-4 py-2"
                                    type="button"
                                    id="orderStatusDropdown"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                  </button>
                                  <div className="dropdown-menu mt-2 px-3 py-2 w-100 text-center " aria-labelledby="orderStatusDropdown">
                                    {!item.isLoadingValidate ? (
                                      <button
                                        onClick={() => {
                                          changeReservationStatus(item.id, 'validate')
                                          const updatedOrders = [...reservationsWithLoading]
                                          updatedOrders[index].isLoadingValidate = true
                                          setReservationsWithLoading(updatedOrders)
                                        }}
                                        className="mt-2 dropdown-item btn btn-success btn-sm me-1 fw-bold"
                                        disabled={item.status === 'validate'}
                                      >
                                        Valider
                                      </button>
                                    ) : (
                                      <button className="mt-2 dropdown-item btn btn-sm me-1 fw-bold" disabled>
                                        <Loading />
                                      </button>
                                    )}

                                    {/* {!item.isLoadingShipping ? (
                                      <button
                                        onClick={() => {
                                          changeReservationStatus(item.id, 'livrer')
                                          const updatedOrders = [...reservationsWithLoading]
                                          updatedOrders[index].isLoadingShipping = true
                                          setReservationsWithLoading(updatedOrders)
                                        }}
                                        className="mt-2 dropdown-item btn btn-info btn-sm me-1 fw-bold"
                                        disabled={item.status === 'livrer'}
                                      >
                                        livré
                                      </button>
                                    ) : (
                                      <button className="mt-2 dropdown-item btn btn-sm me-3 fw-bold" disabled>
                                        <Loading />
                                      </button>
                                    )} */}

                                    {!item.isLoadingAnnuler ? (
                                      <button
                                        onClick={() => {
                                          changeReservationStatus(item.id, 'annuler')
                                          const updatedOrders = [...reservationsWithLoading]
                                          updatedOrders[index].isLoadingAnnuler = true
                                          setReservationsWithLoading(updatedOrders)
                                        }}
                                        className="mt-2 dropdown-item btn btn-warning btn-sm me-3 fw-bold"
                                        disabled={item.status === 'annuler'}
                                      >
                                        Annuler
                                      </button>
                                    ) : (
                                      <button className="mt-2 dropdown-item btn btn-sm me-3 fw-bold" disabled>
                                        <Loading />
                                      </button>
                                    )}
                                  </div>
                                </div>

                                :
                                <button className='btn btn-sm me-1 fw-bold'>
                                  <Loading />
                                </button>

                            }
                          </>
                        }

                        {!true &&
                          <>
                            {
                              !item.isLoadingValidate ?
                                <button
                                  onClick={() => {
                                    changeReservationStatus(item.id, 'validate')
                                    const updatedOrders = [...reservationsWithLoading]
                                    updatedOrders[index].isLoadingValidate = true
                                    setReservationsWithLoading(updatedOrders)
                                  }}
                                  className='btn btn-success btn-sm me-1 fw-bold'
                                  disabled={item.status === 'validate'}
                                >
                                  Valider
                                </button>
                                :
                                <button className='btn btn-sm me-1 fw-bold'>
                                  <Loading />
                                </button>
                            }

                            {
                              !item.isLoadingShipping ?
                                <button
                                  onClick={() => {
                                    changeReservationStatus(item.id, 'livrer')
                                    const updatedOrders = [...reservationsWithLoading]
                                    updatedOrders[index].isLoadingShipping = true
                                    setReservationsWithLoading(updatedOrders)
                                  }}
                                  className='btn btn-info btn-sm me-1 fw-bold'
                                  disabled={item.status === 'livrer'}
                                >
                                  livré
                                </button>
                                :
                                <button className='btn btn-sm me-3 fw-bold'>
                                  <Loading />
                                </button>
                            }

                            {!item.isLoadingAnnuler ?
                              <button
                                onClick={() => {
                                  changeReservationStatus(item.id, 'annuler')
                                  const updatedOrders = [...reservationsWithLoading]
                                  updatedOrders[index].isLoadingAnnuler = true
                                  setReservationsWithLoading(updatedOrders)
                                }}
                                className='btn btn-warning btn-sm me-3 fw-bold'
                                disabled={item.status === 'annuler'}
                              >
                                Annuler
                              </button>
                              :
                              <button className='btn btn-sm me-3 fw-bold'>
                                <Loading />
                              </button>
                            }
                          </>
                        }

                        <button onClick={() => showDetails(index)} className='btn btn-icon btn-light-info btn-sm  me-3'>
                          <i className='bi bi-eye-fill fs-4'></i>
                        </button>

                        <Link to={`/order/order-details/${item.id}`} className='d-none btn btn-icon btn-light-info btn-sm  me-3'>
                          <i className='bi bi-eye-fill fs-4'></i>
                        </Link>

                        <button onClick={() => showLocation(index)} className='btn btn-icon btn-light-primary btn-sm  me-3'>
                          <i className='bi bi-geo-alt fs-4'></i>
                        </button>

                        {!item.isPrinting ? (
                          <button
                            className="btn btn-icon btn-light-dark btn-sm me-3"
                            onClick={() => generatePDF(item.reference, index)}
                          >
                            <i className="bi bi-printer-fill fs-4"></i>
                          </button>
                        ) : (
                          <button className='btn btn-sm me-1 fw-bold'>
                            <Loading />
                          </button>
                        )}

                        <div className="d-none">
                          <div id={`canvas_div_pdf${item.reference}`} className='d-non'>
                            {/* <OrderFacture order={item} /> */}
                          </div>
                        </div>

                        {/* <button onClick={() => DeleteOrder(item.id)} className='btn btn-icon btn-light-danger btn-sm'>
                          <i className='bi bi-trash-fill fs-4'></i>
                        </button> */}
                      </td>

                    </tr>
                  )
                })}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {
          isFilter
            ?
            <Pagination
              count={filterTotalPages}
              page={filterCurrentPage}
              color='primary'
              onChange={handleFilterPageChange}
              className='d-flex justify-content-center'
            />
            :
            <Pagination
              count={totalPages}
              page={currentPage}
              color='primary'
              onChange={handlePageChange}
              className='d-flex justify-content-center'
            />

        }
        {/* <OrderAdressesModal order={selectedOrder} show={showModal} onHide={hideLocationModal} /> */}
        {/* <ReservationDetailsModal reservation={selectedOrder} show={showDetailsModal} onHide={hideDetailsModal} /> */}
        {/* begin::Body */}

      </div>


    </>
  )
}

export default ListReservationOffline