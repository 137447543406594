import { FC } from 'react'
import './LoadingScreen.css'

const LoadingScreen: FC = () => {
    return (
        <div id="loading-screen" className="loading-screen">
            <div className="loading-screen-spinner"></div>
            <span className='text-primary'>Chargement...</span>
        </div>
    )
}

export default LoadingScreen
