import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { CategoryProps } from '../../../_metronic/helpers/Props'
import { apiUrl, categorieImgUrl } from '../../../_metronic/helpers/Helpers'
import axios from 'axios'
import { deleteNotify, frCustomeErrorNotify } from '@mbs-dev/react-helpers'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Pagination } from '@mui/material';
import useApiRequest from '@mbs-dev/api-request'

const ListCategory: React.FC = () => {

  const [isloading, setIsLoading] = useState(false)
  const [categories, setCategories] = useState<CategoryProps[]>([])
  const { apiRequest } = useApiRequest()
  const [isLoadingData, setIsLoadingData] = useState(false)


  // Pagination state
  const [isFilter, setIsFilter] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [filterCurrentPage, setFilterCurrentPage] = useState(1)
  const [filterTotalPages, setFilterTotalPages] = useState(1)
  const [defaultPage, setDefaultPage] = useState(1)

  const stored = localStorage.getItem('storedProdPage')
  useEffect(() => {
    if (stored) {
      setCurrentPage(Number(stored))
    }
  }, [])


  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/categories.jsonld`, {
        params: {
          page: currentPage,
          itemsPerPage: 10
        },
        headers: {
          Accept: 'application/ld+json'
        }
      })

      setCategories(response.data['hydra:member'])
      setTotalPages(Math.ceil(response.data['hydra:totalItems'] / 10))
      setIsLoadingData(false)
    } catch {
      frCustomeErrorNotify()
    }
  }
  useEffect(() => {
    fetchData()
  }, [])


  const DeleteCategorie = async (categorieId: number) => {
    try {
      const response = await axios.delete(`${apiUrl}/categories/${categorieId}`)

      if (response.status === 204) {
        setIsLoading(false)
        toast.success('Categorie est supprimer avec succès');

        // fetchData()
      }
      else {
        toast.error('Erreur lors de la supprission de Categorrie');
      }
    } catch {
      toast.error('Erreur lors de la communication avec le serveur');

    }
  }

  function handlePageChange(event: any, value: any) {
    setCurrentPage(value)
    localStorage.setItem('storedProdPage', String(value))
  }

  function handleFilterPageChange(event: any, value: any) {
    setFilterCurrentPage(value)
    localStorage.setItem('storedProdPage', String(value))
  }


  return (
    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Liste Des Categorie</span>
        </h3>
        <div className='card-toolbar'>
          <Link to='/admin/AddCategory' className='btn btn-sm btn-light-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Nouveau Categorie
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-200px rounded-start'>Image</th>
                <th className='ps-4 min-w-200px'>Nom de categorie</th>
                <th className='min-w-100px text-end rounded-end pe-5'>Action</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {categories.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-70px'>
                          <img
                            src={`${categorieImgUrl}/${item.picture}`}
                            className='w-100'
                            alt=''
                          />
                        </div>
                      </div>
                    </td>

                    <td>
                      <span className='text-dark fw-bold d-block fs-6 ps-6'>
                        {item.title}
                      </span>
                    </td>
                   
                    <td className='text-end'>
                      <Link to={`/admin/update-category/${item.id}`} className='btn btn-icon btn-light-primary btn-sm me-3'>
                        <i className='bi bi-pen-fill fs-4'></i>
                      </Link>
                      <button onClick={() => DeleteCategorie(item.id)} className='btn btn-icon btn-light-danger btn-sm'>
                        <i className='bi bi-trash-fill fs-4'></i>
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
      {
        isFilter
          ? <Pagination
            count={filterTotalPages}
            page={filterCurrentPage}
            color='primary'
            onChange={handleFilterPageChange}
            className='d-flex justify-content-center'
          />
          :
          <Pagination
            count={totalPages}
            page={currentPage}
            color='primary'
            onChange={handlePageChange}
            className='d-flex justify-content-center'
          />
      }
      <div className={`card mt-2 d-none`}>

<div className='card-body p-1'>
  <div className="container">
    <div className="row justify-content-center align-items-center">

      <div className="col-4 money">
        <input
          type="text"
          placeholder='Numéro de page'
          min={1}
          defaultValue={defaultPage}
          className='form-control form-control-lg form-control-dark'
          onChange={(e) => {
            const inputText = e.target.value
            const numbersOnly = inputText.replace(/\D/g, '')
            if (Number(numbersOnly) !== 0 && numbersOnly.length > 0 && Number(numbersOnly) <= Number(totalPages)) {
              setCurrentPage(Number(numbersOnly))
              if (isFilter) {
                setFilterCurrentPage(Number(numbersOnly))
              }
              setDefaultPage(Number(numbersOnly))
              localStorage.setItem('storedProdPage', numbersOnly)
            }
            e.target.value = numbersOnly
          }} />
      </div>

    </div>
  </div>
</div>

</div>

    </div>
  )
}

export default ListCategory