import React, { useState, useEffect, ChangeEvent } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { apiUrl, categorieImgUrl, subcategorieImgUrl } from '../../../_metronic/helpers/Helpers'
// import { AddingNotify, DeleteNotify, FrCustomeErrorNorify, UpdateNotify, errorNotify } from '../../../_metronic/helpers/Toastify'
import { CategoryProps, SubCategoryProps } from '../../../_metronic/helpers/Props'
// import { compressAndAppendImage } from '../../../_metronic/helpers/components/ImageProccessing/Imagecompressor'
import { compressAndAppendImage } from '@mbs-dev/react-image-compressor'
import { useCategoriesContext } from '../../context/CategoriesContext'
import { useSubCategoryContext } from '../../context/SubCategoryContext'
import slugify from 'slugify';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const UpdateSubCategory: React.FC = () => {

  const [isloading, setIsLoading] = useState(false)
  const [isSubloading, setIsSubLoading] = useState(false)
  const { id } = useParams()
  const { categories } = useCategoriesContext()
  const { subcategories } = useSubCategoryContext()
  const [newImage, setNewImage] = useState<File | null>(null)
  const [subcategorie, setSubCategorie] = useState<SubCategoryProps>()
  const [SubExist, setSubExist] = useState(false)
  const [parentTitle, setParentTitle] = useState()
  const [imagefile, setImage] = useState<File | any>(null)
  const [file, setFile] = useState<string>("")
  const [parent, setParent] = useState('')
  const [filteredSubcategories, setFilteredSubcategories] = useState<SubCategoryProps[]>([])


  const Schema = Yup.object().shape({
    title: Yup.string(),
    picture: Yup.string(),
    category: Yup.string().oneOf(
      categories.map((category) => category.id.toString()),
      'Sélectionnez une catégorie valide'
    ),
    // parent: Yup.string().oneOf(
    //   subcategories.map((subcategory) => subcategory.id.toString()),
    //   'Sélectionnez une parent valide'
    // ),
  })

  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/sub_categories/${id}`);

      setSubCategorie(response.data)
      await setValues({
        title: response.data.title,
        picture: response.data.picture,
        category: response.data.category.id,
        parent: response.data.parent ? response.data.parent.id.toString() : '',
      })
    } catch {
    }
  }
  useEffect(() => {
    fetchData()
  }, [])


  const formik = useFormik({
    initialValues: {
      title: "",
      picture: "",
      category: "",
      parent: "",


    },
    validationSchema: Schema,
    onSubmit: async (values, { resetForm }) => {

      try {
        setIsLoading(true)
        const formData = new FormData()
        formData.append('title', values.title)
        const slug = generateSlug(values.title)
        formData.append('parent', values.parent)
        formData.append('slug', slug)
        formData.append('category', values.category)
        if (newImage) {
          await compressAndAppendImage(newImage as unknown as File, formData, 'picture', 0.4)
        }


        const response = await axios.post(`${apiUrl}/subcategory-update/${id}`, formData);
        if (response.status === 200) {
          setIsLoading(false)
          toast.success('Sous Categorie est modifier avec succès');
        } else {
          toast.error('Erreur lors de la modification de Sous Categorrie');
        }

      } catch {
        toast.error('Erreur lors de la communication avec le serveur');

      }
      resetForm()
    },
  })

  function generateSlug(inputString: string): string {
    let string = inputString.replace(/&/g, 'et').replace(/'|’/g, '-').replace('$', '');

    const transliterated = slugify(string, {
      lower: true,
      remove: /[^a-zA-Z0-9 -]/g,
    });

    const slug = transliterated.replace(/ /g, '-').replace(/-+/g, '-').replace(/^-+|-+$/g, '');

    return slug;
  }




  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues
  } = formik

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setNewImage(e.target.files[0]);
    }
  };

  useEffect(() => {
    const selectedCategory = categories.find(category => category.id.toString() === formik.values.category)
    setFilteredSubcategories(selectedCategory ? selectedCategory.subCategories : [])
  }, [formik.values.category, categories])
  



  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#product'
          aria-expanded='true'
          aria-controls='product'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Modifier Une sous Categorie</h3>
          </div>
        </div>

        <div id='product' className='collapse show'>
          <form onSubmit={handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>

              <div className='row mb-6'>
                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-lg-6 col-md-6 fv-row'>
                      <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Nom De sous Categorie</label>

                      <input
                        type='text'
                        name='title'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Nom de categorie'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                      />
                      {formik.touched.title && formik.errors.title && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.title}</div>
                        </div>
                      )}
                    </div>

                    <div className='col-lg-6 col-md-6 fv-row'>
                      <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Ancienne image</label>

                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-70px'>
                          <img
                            src={`${subcategorieImgUrl}/${values.picture}`}
                            className='w-100 shadow'
                            alt=''
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>


                <div className='col-lg-6 col-md-6 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Nouveau Image</label>
                  <input
                    type="file"
                    name="picture"
                    accept=".png, .jpg, .jpeg, .webp"
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    onChange={handleImageChange} />

                  {newImage ? (
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-70px'>
                        <img src={URL.createObjectURL(newImage)} alt='' className='w-100 shadow mt-2' />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                </div>
              </div>


              <div className='row mb-6'>
              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Categories</label>
                <select
                  className="form-select"
                  value={formik.values.category}
                  onChange={(event) => {
                    formik.setFieldValue('category', event.target.value)
                  }}
                >
                  <option value="">Sélectionnez une catégorie</option>
                  {categories.map((categorie, index) => (
                    <option key={index} value={categorie.id}>
                      {categorie.title}
                    </option>
                  ))}
                </select>
                {formik.touched.category && formik.errors.category && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.category}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
                <div className='col-lg-6 col-md-6 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Parent</label>
                  <select
                    className='form-select'
                    value={formik.values.parent}
                    onChange={(event) => formik.setFieldValue('parent', event.target.value)}
                  >
                    <option value="">Sélectionnez un parent</option>
                    {filteredSubcategories.map((subcat) => (
                      <option key={subcat.id} value={subcat.id}>
                        {subcat.title}
                      </option>
                    ))}
                  </select>
                  {touched.parent && errors.parent && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{errors.parent}</div>
                    </div>
                  )}
                </div>
              </div>


            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={isloading}>
                {!isloading && 'Save Changes'}
                {isloading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>

      </div>
    </>
  )
}

export default UpdateSubCategory