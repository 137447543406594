import React from 'react'
import { Modal } from 'react-bootstrap'
import { OfflineOrderModalProps } from '../../../_metronic/helpers/Props'
import OrderAdresseItem from '../../../_metronic/helpers/components/OrderAdresseItem'

const OfflineOrderAdressesModal: React.FC<OfflineOrderModalProps> = ({ order, show, onHide }) => {

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title className='fs-3'>Details De Livraison</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <OrderAdresseItem label='Nom et Prenom' value={order?.fullName}  style='pb-3' />
        <OrderAdresseItem label='Telephone' value={`${order?.telephone}`} style='pb-3' />
        <OrderAdresseItem label='Adresse Email' value={`${order?.email}`} style='pb-3' />
        <OrderAdresseItem label='Adresse' value={`${order?.adresse}`} style='pb-3' />
      </Modal.Body>
    </Modal>
  )
}

export default OfflineOrderAdressesModal