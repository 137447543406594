import { useState, useEffect, ChangeEvent } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import { compressAndAppendImage } from '@mbs-dev/react-image-compressor';
import { initCategory, initSubcategory } from '../../helpers/validation/FormikHelper'
import { categorySchema, subCategorySchema } from '../../helpers/validation/YupSchema'
import { useCategoriesContext } from '../../context/CategoriesContext'
import { postNotify, deleteNotify, frCustomeErrorNotify, updateNotify, errorNotify, slugGenerator } from '@mbs-dev/react-helpers'
import useApiRequest from '@mbs-dev/api-request'
import SubCategoryItem from './SubCategoryItem'
import { useSubCategoryContext } from '../../context/SubCategoryContext'
import { CategoryProps } from '../../../_metronic/helpers/Props';
import SubmitButton from '../../../_metronic/helpers/SubmitButton/SubmitButton';
import CardHeader from '../../modules/accounts/components/CardHeader';
import { apiUrl, categorieImgUrl } from '../../../_metronic/helpers/Helpers';


const UpdateCategory: React.FC = () => {

  
  const { id } = useParams()
  const { apiRequest } = useApiRequest()
  const [newImage, setNewImage] = useState<File | null>(null)
  const [categorie, setCategorie] = useState<CategoryProps>()
  const [SubExist, setSubExist] = useState(false)
  const [isloading, setIsLoading] = useState(false)
  const [isSubloading, setIsSubLoading] = useState(false)
  const { categories } = useCategoriesContext()
  const { subcategories } = useSubCategoryContext()



  const fetchData = async () => {
    try {
      const response = await apiRequest({
        route: `${apiUrl}/categories/${id}`,
        method: 'GET',
      })
      setCategorie(response.data)
      if (response.data.subCategories.length > 0) {
        setSubExist(true)
      }

      await setValues({
        title: response.data.title,
        description: response.data.description !== null ? response.data.description : '',
        parent: response.data.parent,
        picture: response.data.picture
      })

    } catch {
      frCustomeErrorNotify()
    }
  }
  useEffect(() => {
    fetchData()
  }, [])


  const formik = useFormik({
    initialValues:
    initCategory,
    validationSchema: categorySchema(categories, true),
    onSubmit: async (values, { resetForm }) => {
      try {
        setIsLoading(true)
        const formData = new FormData()
        formData.append('title', values.title)
        const slug = slugGenerator(values.title)
        formData.append('slug', slug)

        if (newImage) {
          await compressAndAppendImage(newImage as File, formData, 'picture', 0.4)
        }

        const response = await apiRequest({
          route: `${apiUrl}/category-update/${id}`,
          method: 'POST',
          data: formData,
        })

        if (response.status === 200) {
          setIsLoading(false)
          updateNotify('Categorie')
        }

      } catch {
        frCustomeErrorNotify()
        setIsLoading(false)

      }

      resetForm()
    },
  })

  const DeleteCategorie = async (categorieId: number) => {
    try {
      const response = await apiRequest({
        route: `${apiUrl}/categories/${categorieId}`,
        method: 'DELETE',
      })

      if (response.status === 204) {
        deleteNotify('Sous Categorie')
        fetchData()
      }
    } catch {
      frCustomeErrorNotify()
    }
  }


  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues
  } = formik

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setNewImage(e.target.files[0])
    }
  }

  // Adding Sub
  const postData = async (formData: FormData) => {
    try {
      setIsSubLoading(true)
      await compressAndAppendImage(subFormik.values.picture as unknown as File, formData, 'picture', 0.4)

      const response = await apiRequest({
        route: `${apiUrl}/sub_categories`,
        method: 'POST',
        data: formData
      })

      if (response.status === 201) {
        postNotify('Sous Categorie')
        fetchData()
        setIsSubLoading(false)
      }

    } catch {
      frCustomeErrorNotify()
      setIsSubLoading(false)
    }
  }

  const subFormik = useFormik({
    initialValues: initSubcategory,
    validationSchema: subCategorySchema,
    onSubmit: async (values) => {

      setIsSubLoading(true)
      const formData = new FormData()
      formData.append('title', values.title)
      formData.append('parent', String(id))


      

     
    
    },
  })


  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <CardHeader target='category' label='Modifier Une Categorie' />

        <div id='category' className='collapse show'>
          <form onSubmit={handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>

              <div className='row mb-6'>
                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-lg-6 col-md-6 fv-row'>
                      <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Nom De Categorie</label>

                      <input
                        type='text'
                        name='title'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Nom de categorie'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                      />
                      {formik.touched.title && formik.errors.title && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.title}</div>
                        </div>
                      )}
                    </div>

                    <div className='col-lg-6 col-md-6 fv-row'>
                      <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Ancienne image</label>

                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-70px'>
                          <img
                            src={`${categorieImgUrl}/${values.picture}`}
                            className='w-100 shadow'
                            alt=''
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>

                <div className='col-lg-6 col-md-6 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Nouveau Image</label>
                  <input
                    type="file"
                    name="picture"
                    accept=".png, .jpg, .jpeg, .webp"
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    onChange={handleImageChange} />

                  {newImage ? (
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-70px'>
                        <img src={URL.createObjectURL(newImage)} alt='' className='w-100 shadow mt-2' />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                </div>
              </div>

            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <SubmitButton isLoading={isloading} buttonLabel='Enregistrer les modifications' />
            </div>
          </form>
        </div>

      </div>

      {SubExist ?

        <div className="card mb-5 mb-xl-10">
          <div
            className='card-header border-0 cursor-pointer'
            role='button'
            data-bs-toggle='collapse'
            data-bs-target='#sub'
            aria-expanded='true'
            aria-controls='sub'
          >
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>List Des Sous-Categories</h3>
            </div>
          </div>
          <div id='sub' className='collapse show'>
            <div className='card-body border-top p-9'>

              <div className='table-responsive'>
                <table className='table align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bold text-muted bg-light'>
                      <th className='ps-4 min-w-125px'>Image</th>
                      {/* <th className='ps-4 min-w-100px'>Nouveau Image</th> */}
                      <th className='ps-4 min-w-150px'>Categorie</th>
                      <th className='ps-4 min-w-125px'>Parent</th>
                      <th className='min-w-100px text-end rounded-end pe-5'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      categorie?.subCategories.map((sub, index)  => {                        
                        return (
                          <SubCategoryItem
                            deleteSubCategorie={DeleteCategorie}
                            key={index}
                            data={sub}
                            categorieId={categorie.id}
                            fetchData={fetchData}
                          />
                        )
                      }
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        : ''
      }

      {/* Start Add Sub Categorie */}
      <div className='card mb-5 mb-xl-10'>
        <CardHeader target='add-sub' label='Ajouter Une Sous Categorie' />

        <div id='add-sub' className='collapse show'>
          <form onSubmit={subFormik.handleSubmit} noValidate className='form'>

            <div className='card-body border-top p-9'>

              <div className='row mb-6'>
                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-lg-6 col-md-6 fv-row'>
                      <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Nom de sous categorie</label>

                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Nom de categorie'
                        {...subFormik.getFieldProps('title')}
                      />
                      {subFormik.touched.title && subFormik.errors.title && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{subFormik.errors.title}</div>
                        </div>
                      )}
                    </div>

                    <div className='col-lg-6 col-md-6 fv-row'>
                      <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Image</label>

                      <input
                        type="file"
                        name="image"
                        accept=".png, .jpg, .jpeg, .webp"
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          if (e.target.files && e.target.files[0]) {
                            subFormik.setFieldValue('picture', e.target.files[0])
                          }
                        }} />
                      {subFormik.touched.picture && subFormik.errors.picture && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{subFormik.errors.picture}</div>
                        </div>
                      )}

                    </div>
                  </div>   
                </div>
              </div>

              <div className='row mb-6'>


                  <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>parent</label>

                    <select
                    className="form-select"
                    value={formik.values.parent}
                    onChange={(event) => {
                      formik.setFieldValue('parent', event.target.value)
                    }}
                  >
                    <option value="">Sélectionnez un parent</option>
                       
                      {/* <option value="parent">Parent</option> */}
                      {subcategories.map((subcategories: any, index) => {
                        return (
                          <option key={index} value={subcategories.id}>
                            {subcategories.title}
                          </option>
                        )
                      })}
                    </select>
                    {formik.touched.parent && formik.errors.parent && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.parent}</div>
                      </div>
                    )}
                  </div>
                  </div>



            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <SubmitButton isLoading={isSubloading} buttonLabel='Enregistrer les modifications' />
            </div>
          </form>
        </div>
      </div>
      {/* End Add Sub Categorie */}

    </>
  )
}

export default UpdateCategory