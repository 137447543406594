import React, { createContext, useContext, useState, useEffect } from 'react';
import { CategoryProps, SubCategoryProps } from '../../_metronic/helpers/Props';
// import { useApiRequest } from '../../_metronic/helpers/ApiRequest';
import axios from 'axios';
import { apiUrl } from '../../_metronic/helpers/Helpers';


const SubCategoriesParentContext = createContext<{
    subCategoriesParent: SubCategoryProps[];
}>({
    subCategoriesParent: [],
});

export const useSubCategoriesParentContext = () => useContext(SubCategoriesParentContext);

export const SubCategoriesParentProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [subCategoriesParent, setSubCategoriesParent] = useState<SubCategoryProps[]>([]);
    // const apiRequest = useApiRequest()

    const fetchData = async () => {
        try {
          const response = await axios.get(`${apiUrl}/sub_categories`)
    
          setSubCategoriesParent(response.data)
          
        } catch {
        }
      }
      useEffect(() => {
        fetchData()
      }, [])

      return (
        <>
            <SubCategoriesParentContext.Provider value={{subCategoriesParent}}>
                {children}
            </SubCategoriesParentContext.Provider>
        </>

    );
};
