import React, { useState, ChangeEvent } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import axios from 'axios'
import { apiUrl } from '../../../_metronic/helpers/Helpers'
import { useProductContext } from '../../context/ProductContext'
import {compressAndAppendImage} from '@mbs-dev/react-image-compressor';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import slugify from 'slugify';



const AddVariante: React.FC = () => {

  const [loading, setIsLoading] = useState(false)
  const { product } = useProductContext()


  const Schema = Yup.object().shape({
    title: Yup.string().required('Le nom de categorie est obligatoire'),
    picture: Yup.string(),
    price: Yup.string().required("prix de produit est obligatoire"),
    point_consommable: Yup.string().required("point consommable de produit est obligatoire"),
    point_beneficier: Yup.string().required("point consommable de produit est obligatoire"),
    product: Yup.string().oneOf(
      product.map((product) => product.id.toString()),
      'Sélectionnez un produit valide'
    )
    
    
   
  })

  const formik = useFormik({
    initialValues: {
      title: "",
      picture: "",
      price: "",
      point_consommable:"",
      point_beneficier:"",
      product:""
    


    },


    validationSchema: Schema,
    onSubmit: async (values) => {
      setIsLoading(true)

      const formData = new FormData()
      formData.append('title', values.title)
      formData.append('price', values.price)
      formData.append('point_consommable', values.point_consommable)
      formData.append('point_beneficier', values.point_beneficier)
      formData.append('product', values.product)
      await compressAndAppendImage(values.picture as unknown as File , formData,'picture',0.4)
      const slug = generateSlug(values.title)
      formData.append('slug', slug)
      console.log(formData);
      

      const response = await axios.post(`${apiUrl}/variantes`, formData)
      try{
      if (response.status === 200) {
        setIsLoading(false)
        toast.success('Variante est enregistré avec succès');
      }
      else {
        toast.error('Erreur lors de l\'enregistrement de produit');
      }
    } catch (error) {
      toast.error('Erreur lors de la communication avec le serveur');
    }


    },
  })
  function generateSlug(inputString: string): string {
    let string = inputString.replace(/&/g, 'et').replace(/'|’/g, '-').replace('$', '');

    const transliterated = slugify(string, {
        lower: true,
        remove: /[^a-zA-Z0-9 -]/g,
    });

    const slug = transliterated.replace(/ /g, '-').replace(/-+/g, '-').replace(/^-+|-+$/g, '');

    return slug;
}


  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#variante'
        aria-expanded='true'
        aria-controls='varinate'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Ajouter Une variante</h3>
        </div>
      </div>

      <div id='product' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>

          <div className='card-body border-top p-9'>

            <div className='row mb-6'>
              <div className='col-lg-12'>
                <div className='row'>
                  <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Nom de variante</label>

                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Nom de categorie'
                      {...formik.getFieldProps('title')}
                    />
                    {formik.touched.title && formik.errors.title && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.title}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Image</label>

                    <input
                      type="file"
                      name="image"
                      accept=".png, .jpg, .jpeg, .webp"
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {

                        if (e.target.files && e.target.files[0]) {
                          // setFile(URL.createObjectURL(e.target.files[0]))
                          // setImage(e.target.files[0])
                          formik.setFieldValue('picture', e.target.files[0])
                        }
                      }} />
                    {formik.touched.picture && formik.errors.picture && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.picture}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-3 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>prix</label>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='prix'
                      {...formik.getFieldProps('price')}
                    />
                    {formik.touched.price && formik.errors.price && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.price}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-4 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Point consommable</label>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Point_consommable'
                      {...formik.getFieldProps('point_consommable')}
                    />
                    {formik.touched.point_consommable && formik.errors.point_consommable && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.point_consommable}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-4 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Point beneficier</label>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Point beneficier'
                      {...formik.getFieldProps('point_beneficier')}
                    />
                    {formik.touched.point_beneficier && formik.errors.point_beneficier && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.point_beneficier}</div>
                      </div>
                    )}
                  </div>
                </div>

                    <div className='row mb-6'>
                    <div className='col-lg-6 col-md-6 fv-row'>
                      <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Produit</label>

                      <select
                        // name="categorie"
                        className="form-select"
                        value={formik.values.product}
                        onChange={(event) => {
                          formik.setFieldValue('product', event.target.value)
                        }}
                      >
                        <option value="produit">Produit</option> 
                        {product.map((products: any, index) => {
                          return (
                            <option key={index} value={products.id}>
                              {products.title}
                            </option>
                          )
                        })}
                      </select>
                      {formik.touched.product && formik.errors.product && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.product}</div>
                        </div>
                      )}
                    </div>
                    </div>

              </div>
            </div>

          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>

        </form>
      </div>
    </div>
  )
}

export default AddVariante